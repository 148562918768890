import React from "react";

export default function OrgAdminTACSettings() {

    return (
        <div>
            <h1>Org Admin TAC Settings</h1>
        </div>
    );
}
