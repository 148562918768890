import React, { useState } from "react";
import { Button, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import formatdate from "../Helpers/DateFormatter";
import { Link } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";

const RecursiveTableRow = ({ data, selectedGroup, handleSelectChange, ...rest }) => {
    const [open, setOpen] = useState(false);
    // console.log("");

    if (data?.children?.length === 0) {
        return (
            <TableRow>
                <TableCell></TableCell>
                <TableCell>
                    <Typography>{data?.code}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{data?.name}</Typography>
                </TableCell>
                <TableCell>
                    {data?.parentId?.substring(0, 12)} {data?.parentId?.length >= 12 && "..."}
                </TableCell>
                <TableCell>
                    <Typography>{data?.groupType?.name}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{formatdate(data?.modifiedUtc)}</Typography>
                </TableCell>
                <TableCell align="center">
                    {/* <Checkbox
                        color="primary"
                        checked={selectedGroup === data.id}
                        onChange={() => {
                            console.log(data.id);
                            handleSelectChange(data.id);
                        }}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    /> */}
                    <Button
                        component={Link}
                        to={`/admin/organizations/${JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId}/groups/new`}
                        state={{ parentId: data.id }}
                        startIcon={<AddIcon />}
                    >
                        Child Group
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        sx={{ ml: data?.parentId !== null ? 3 : undefined }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Typography>{data?.code}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{data?.name}</Typography>
                </TableCell>
                <TableCell>
                    <Typography
                    // style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                        {data?.parentId?.substring(0, 12)} {data?.parentId?.length >= 12 && "..."}
                        {/* {data?.parentId} */}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography>{data?.groupType?.name}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>{formatdate(data?.modifiedUtc)}</Typography>
                </TableCell>
                <TableCell align="center">
                    {/* <Checkbox
                        color="primary"
                        checked={selectedGroup === data.id}
                        onChange={() => {
                            console.log(data.id);
                            handleSelectChange(data.id);
                        }}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    /> */}
                    <Button
                        component={Link}
                        to={`/admin/organizations/${JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId}/groups/new`}
                        state={{ parentId: data.id }}
                        startIcon={<AddIcon />}
                    >
                        Child Group
                    </Button>
                </TableCell>
            </TableRow>
            {open &&
                data.children.map((c, i) => (
                    <RecursiveTableRow
                        key={i}
                        data={c}
                        selectedGroup={selectedGroup}
                        handleSelectChange={(id) => handleSelectChange(id)}
                    />
                ))}
        </React.Fragment>
    );
};

export default RecursiveTableRow;
