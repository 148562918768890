import React, { useState } from "react";
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Slider,
    Typography,
    Checkbox,
    FormControlLabel,
    Button,
} from "@mui/material";

const TireResultFilter = ({ onFilterChange }) => {
    // State for filters
    const [tireType, setTireType] = useState("");
    const [brand, setBrand] = useState("");
    const [size, setSize] = useState([15, 22]);
    const [price, setPrice] = useState([50, 500]);
    const [onSale, setOnSale] = useState(false);

    // Handlers for changes
    const handleTireTypeChange = (event) => setTireType(event.target.value);
    const handleBrandChange = (event) => setBrand(event.target.value);
    const handleSizeChange = (event, newValue) => setSize(newValue);
    const handlePriceChange = (event, newValue) => setPrice(newValue);
    const handleOnSaleChange = (event) => setOnSale(event.target.checked);

    // Function to reset filters
    const resetFilters = () => {
        setTireType("");
        setBrand("");
        setSize([15, 22]);
        setPrice([50, 500]);
        setOnSale(false);
        onFilterChange({});
    };

    // Apply filters
    const applyFilters = () => {
        onFilterChange({
            tireType,
            brand,
            size,
            price,
            onSale,
        });
    };

    return (
        <Box sx={{ px: 2, width: "100%" }}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
                10 results 84
            </Typography>
            <Typography variant="h6" gutterBottom>
                Tire Product Filter
            </Typography>

            {/* Tire Type Filter */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="tire-type-label">Tire Type</InputLabel>
                <Select labelId="tire-type-label" value={tireType} onChange={handleTireTypeChange} label="Tire Type">
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Summer">Summer</MenuItem>
                    <MenuItem value="Winter">Winter</MenuItem>
                    <MenuItem value="All-Season">All-Season</MenuItem>
                </Select>
            </FormControl>

            {/* Brand Filter */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="brand-label">Brand</InputLabel>
                <Select labelId="brand-label" value={brand} onChange={handleBrandChange} label="Brand">
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Michelin">Michelin</MenuItem>
                    <MenuItem value="Bridgestone">Bridgestone</MenuItem>
                    <MenuItem value="Pirelli">Pirelli</MenuItem>
                </Select>
            </FormControl>

            {/* Tire Type Filter */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="tire-type-label">Program Category</InputLabel>
                <Select labelId="tire-type-label" value={tireType} onChange={handleTireTypeChange} label="Tire Type">
                    <MenuItem value="All">ELT</MenuItem>
                    <MenuItem value="Summer">OEA</MenuItem>
                    <MenuItem value="Winter">OEM</MenuItem>
                    <MenuItem value="All-Season">WIN</MenuItem>
                </Select>
            </FormControl>

            {/* Size Filter */}
            <Typography gutterBottom>Size (inches)</Typography>
            <Slider
                value={size}
                onChange={handleSizeChange}
                valueLabelDisplay="auto"
                min={15}
                max={22}
                sx={{ mb: 2 }}
            />

            {/* Price Filter */}
            <Typography gutterBottom>Price ($)</Typography>
            <Slider
                value={price}
                onChange={handlePriceChange}
                valueLabelDisplay="auto"
                min={50}
                max={500}
                sx={{ mb: 2 }}
            />

            {/* On Sale Checkbox */}
            <FormControlLabel control={<Checkbox checked={onSale} onChange={handleOnSaleChange} />} label="On Sale" />

            {/* Buttons */}
            <Box sx={{ mt: 2 }}>
                <Button variant="contained" onClick={applyFilters} sx={{ mr: 1 }}>
                    Apply
                </Button>
                <Button variant="outlined" onClick={resetFilters}>
                    Reset
                </Button>
            </Box>
        </Box>
    );
};

export default TireResultFilter;
