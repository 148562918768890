import React, { useEffect, useState } from "react";
// import MenuItem from "./MenuItem";
// import settings from "../../settings/api";
// import { List } from "@mui/material";

// const StandardMenuItems = (props) => {
//     // const [organizationId, setOrganizationId] = useState("");
//     const [items, setItems] = useState([]);

//     useEffect(() => {
//         // const storedOrganization = localStorage.getItem("organization");
//         // // console.log(storedOrganization);
//         // if (storedOrganization) {
//         //     const parsedOrganization = JSON.parse(storedOrganization);
//         //     setOrganizationId(parsedOrganization.id);
//         // }
//         GetStandardMenu();
//     }, []);

//     const GetStandardMenu = () => {
//         const token = JSON.parse(localStorage.getItem("user")).token;
//         // console.log(token);
//         // return
//         fetch(settings.api().standardMenu, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         })
//             .then((res) => {
//                 // console.log(res);
//                 return res.json();
//             })
//             .then((data) => {
//                 // console.log(data);
//                 // setMenuList(data.items);
//                 //  setRoleMenu(data.items);
//                 setItems(data.items);
//             })
//             .catch((err) => console.error(err));
//     };
//     // const standardMenuItems = GetStandardMenu();
//     // const standardMenuItems = [
//     //     {
//     //         menuItem: true,
//     //         icon: "dashboard",
//     //         path: "/portal",
//     //         label: "Dashboard",
//     //     },
//     //     {
//     //         menuItem: false,
//     //         label: JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationName,
//     //     },
//     //     {
//     //         menuItem: true,
//     //         icon: "videocam",
//     //         label: "OnDemand",
//     //         children: [
//     //             {
//     //                 path: "/portal/lessons",
//     //                 label: "Lessons",
//     //             },
//     //         ],
//     //     },
//     //     {
//     //         menuItem: true,
//     //         icon: "school",
//     //         path: "/carsability",
//     //         label: "CARSability",
//     //         // permission: [Roles.SALES_REP, Roles.WCRM],
//     //         // section: 1,
//     //         children: [
//     //             {
//     //                 // icon: "dashboard",
//     //                 path: "/portal/assessments",
//     //                 label: "Assessments",
//     //             },
//     //         ],
//     //     },
//     //     {
//     //         menuItem: true,
//     //         icon: "contact_phone",
//     //         path: "/tac",
//     //         label: "TAC",
//     //         // permission: [Roles.SALES_REP, Roles.WCRM],
//     //         // section: 1,
//     //         children: [
//     //             {
//     //                 // icon: "contact_phone",
//     //                 path: "/portal/cases",
//     //                 label: "Cases",
//     //             },
//     //         ],
//     //     },
//     //     {
//     //         menuItem: false,
//     //         label: "Management",
//     //     },
//     //     {
//     //         menuItem: true,
//     //         icon: "person_pin",
//     //         path: "/portal/my-site",
//     //         label: "My Site",
//     //         // permission: [Roles.SALES_REP, Roles.WCRM],
//     //         // section: 1,
//     //     },
//     // ];

//     return (
//         <List>
//             {items.length > 0 &&
//                 items.map((item, i) => (
//                     <div key={i}>
//                         <MenuItem
//                             openDrawer={props.open}
//                             isMenuItem={item.menuItem}
//                             label={item.label}
//                             children={item.children ? item.children : undefined}
//                             path={item.path}
//                             icon={item.icon}
//                             subMenu={item.subMenu ? item.subMenu : []}
//                             subtitle={item.subtitle}
//                             itemType={item.itemType}
//                         />
//                     </div>
//                 ))}
//         </List>
//     );
// };

// export default StandardMenuItems;

const standardMenuItems = [
    {
        menuItem: true,
        icon: "dashboard",
        path: "/portal",
        label: "Dashboard",
    },
    {
        menuItem: false,
        label: JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationName,
    },
    {
        menuItem: true,
        icon: "videocam",
        label: "OnDemand",
        children: [
            {
                path: "/portal/lessons",
                label: "Lessons",
            },
        ],
    },
    {
        menuItem: true,
        icon: "school",
        path: "/carsability",
        label: "CARSability",
        // permission: [Roles.SALES_REP, Roles.WCRM],
        // section: 1,
        children: [
            {
                // icon: "dashboard",
                path: "/portal/assessments",
                label: "Assessments",
            },
        ],
    },
    {
        menuItem: true,
        icon: "contact_phone",
        path: "/tac",
        label: "TAC",
        // permission: [Roles.SALES_REP, Roles.WCRM],
        // section: 1,
        children: [
            {
                // icon: "contact_phone",
                path: "/portal/cases",
                label: "Cases",
            },
        ],
    },
    {
        menuItem: false,
        label: "Management",
    },
    {
        menuItem: true,
        icon: "person_pin",
        path: "/portal/my-site",
        label: "My Site",
        // permission: [Roles.SALES_REP, Roles.WCRM],
        // section: 1,
    },
];

export default standardMenuItems;
