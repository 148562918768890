// import React, { useEffect, useState } from "react";
// import MenuItem from "./MenuItem";
// import { List } from "@mui/material";
// // import from "react";

// const AdminMenuItems = (props) => {
//     const [adminItems, setAdminItems] = useState([]);
//     // const [oId, setOId] = useState("");
//     // const organizationId = localStorage.getItem("organization")
//     //     ? JSON.parse(localStorage.getItem("organization")).id
//     //     : "";

//     const [organizationId, setOrganizationId] = useState("");

//     useEffect(() => {
//         const storedOrganization = localStorage.getItem("organization");
//         if (storedOrganization) {
//             const parsedOrganization = JSON.parse(storedOrganization);
//             setOrganizationId(parsedOrganization.id);
//         }
//     }, []);

//     useEffect(() => {
//         setAdminItems(adminMenuItems);

//         // return () => {};
//     }, [organizationId]);

//     console.log(adminItems);

//     const adminMenuItems = [
//         {
//             menuItem: false,
//             label: "Administration",
//         },
//         {
//             menuItem: true,
//             icon: "boy",
//             path: "/users",
//             label: "Users",
//             children: [
//                 {
//                     icon: "maps_home_work",
//                     path: `/admin/organizations/${organizationId}/users`,
//                     label: "User List",
//                 },
//             ],
//         },
//         {
//             menuItem: true,
//             icon: "apartment",
//             path: "/organizations",
//             label: "Sites",
//             children: [
//                 {
//                     icon: "maps_home_work",
//                     path: `/admin/organizations/${organizationId}/sites`,
//                     label: "Site List",
//                 },
//                 {
//                     icon: "maps_home_work",
//                     path: `/admin/organizations/${organizationId}/groups`,
//                     label: "Groups",
//                 },
//                 {
//                     icon: "maps_home_work",
//                     path: `/admin/organizations/${organizationId}/group-types`,
//                     label: "Group Types",
//                 },
//             ],
//         },
//     ];
//     return (
//         <List>
//             {adminItems.length > 0 &&
//                 adminItems.map((item, i) => (
//                     <div key={i}>
//                         <MenuItem
//                             openDrawer={props.open}
//                             isMenuItem={item.menuItem}
//                             label={item.label}
//                             children={item.children ? item.children : undefined}
//                             path={item.path}
//                             icon={item.icon}
//                             subMenu={item.subMenu ? item.subMenu : []}
//                             subtitle={item.subtitle}
//                             itemType={item.itemType}
//                         />
//                         {/* {(item.section !== section) && <Divider />} */}
//                         {/* {divider && <Divider />} */}
//                     </div>
//                 ))}
//         </List>
//     );
// };

// export default AdminMenuItems;

const organizationId = localStorage.getItem("userOrganizationInfo") ? JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId : "";
// console.log(organizationId);

const adminMenuItems = [
    {
        menuItem: false,
        label: "Services",
    },
    {
        menuItem: true,
        icon: "assessment",
        path: "/admin/tire-quote",
        label: "Tire Quote",
        children: [
            // {
            //     path: `/admin/organizations/${organizationId}/tire-quote`,
            //     label: "Tire Qutoe",
            // },
            {
                path: `/admin/organizations/${organizationId}/tire-quote/pricing`,
                label: "Pricing",
            },
            {
                path: `/admin/organizations/${organizationId}/tire-quote/featured`,
                label: "Featured",
            },
            {
                path: `/admin/organizations/${organizationId}/tire-quote/promotions`,
                label: "Promotions",
            },
            {
                path: `/admin/organizations/${organizationId}/tire-quote/stock`,
                label: "Stock",
            },
            {
                path: `/admin/organizations/${organizationId}/tire-quote/tpo`,
                label: "TPO",
            },
            {
                path: `/admin/organizations/${organizationId}/tire-quote/settings`,
                label: "Settings",
            },

        ],
    },
    {
        menuItem: false,
        label: "Organization Admin",
    },
    {
        menuItem: true,
        icon: "boy",
        path: "/users",
        label: "Users",
        // permission: [Roles.SALES_REP, Roles.WCRM],
        // section: 1,
        children: [
            {
                icon: "maps_home_work",
                // path: `/admin/organizations/${
                //     JSON.parse(localStorage.getItem("organizationInfo"))?.organizationId
                // }/users`,
                path: `/admin/organizations/${organizationId}/users`,
                label: "User List",
                // permission: [Roles.SALES_REP, Roles.WCRM],
                // section: 1,
            },
        ],
    },
    {
        menuItem: true,
        icon: "apartment",
        path: "/organizations",
        label: "Sites",
        // permission: [Roles.SALES_REP, Roles.WCRM],
        // section: 1,
        children: [
            {
                icon: "maps_home_work",
                path: `/admin/organizations/${organizationId}/sites`,
                label: "Site List",
                // permission: [Roles.SALES_REP, Roles.WCRM],
                // section: 1,
            },
            {
                icon: "maps_home_work",
                path: `/admin/organizations/${organizationId}/groups`,
                label: "Groups",
                // permission: [Roles.SALES_REP, Roles.WCRM],
                // section: 1,
            },
            {
                icon: "maps_home_work",
                path: `/admin/organizations/${organizationId}/group-types`,
                label: "Group Types",
                // permission: [Roles.SALES_REP, Roles.WCRM],
                // section: 1,
            },
        ],
    },
    // {
    //     menuItem: true,
    //     icon: "apartment",
    //     path: "/tire-quote",
    //     label: "Tire Quote",
    //     children: [
    //         {
    //             icon: "maps_home_work",
    //             path: "/:id",
    //             label: "Organization",
    //         },
    //     ],
    // },
    {
        menuItem: false,
        label: "System Admin",
    },
    {
        menuItem: true,
        icon: "psychology",
        path: "/system/training",
        label: "Training",
        // permission: [Roles.SALES_REP, Roles.WCRM],
        // section: 1,
        children: [
            {
                path: `/admin/system/training/curriculums`,
                label: "Curriculums",
                // permission: [Roles.SALES_REP, Roles.WCRM],
                // section: 1,
            },
            {
                path: `/admin/system/training/courses`,
                label: "Courses",
            },
            {
                path: `/admin/system/training/lessons`,
                label: "Lessons",
            },
            {
                path: `/admin/system/training/resources`,
                label: "Resources",
            },
            {
                path: `/admin/system/training/evaluations`,
                label: "Evaluations",
            },
        ],
    },
    {
        menuItem: true,
        icon: "assessment",
        path: "/system/assessments",
        label: "Assessments",
        children: [
            {
                path: `/admin/system/assessments`,
                label: "Assessments",
            },
            {
                path: `/admin/system/assessments/occupations`,
                label: "Occupations",
            },
        ],
    },
    {
        menuItem: true,
        icon: "support_agent",
        path: "/system/tac",
        label: "TAC",
        children: [
            {
                path: `/admin/system/tac/categories`,
                label: "Categories",
            },
        ],
    },
];

export default adminMenuItems;
