import React from "react";

export default function OrgAdminTrainingCourses() {

    return (
        <div>
            <h1>Org Admin Training Courses</h1>
        </div>
    );
}
