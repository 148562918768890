import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { UsersList } from "../AdminPages/Users/UsersList";
import { Link } from "react-router-dom";
import { Conversions } from "../../components/ConversionChart";
import { TopProducts } from "../../components/TopSellingProductCard";
import settings from "../../settings/api";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";
import formatPhone from "../../Helpers/PhoneFormatter";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FaxIcon from "@mui/icons-material/Fax";
import StoreIcon from "@mui/icons-material/Store";
import formatPostalCode from "../../Helpers/PostalCodeFormatter";
import VerifiedIcon from "@mui/icons-material/Verified";
import Settings from "../../components/MySite/Settings";
import ReactApexChart from "react-apexcharts";
import { dayjs } from "../../Helpers/dayjs";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ marginTop: "20px" }}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}
const users = [
    {
        id: "MSG-001",
        content: "Shop Technian",
        author: { name: "Alcides Antonio", avatar: "/assets/avatar-10.png", status: "online" },
        createdAt: dayjs().subtract(2, "minute").toDate(),
    },
    {
        id: "MSG-002",
        content: "Sales Manager",
        author: { name: "Marcus Finn", avatar: "/assets/avatar-9.png", status: "offline" },
        createdAt: dayjs().subtract(56, "minute").toDate(),
    },
    {
        id: "MSG-003",
        content: "Sales Representative",
        author: { name: "Carson Darrin", avatar: "/assets/avatar-3.png", status: "online" },
        createdAt: dayjs().subtract(3, "hour").subtract(23, "minute").toDate(),
    },
    {
        id: "MSG-004",
        content: "Sales Representative",
        author: { name: "Fran Perez", avatar: "/assets/avatar-5.png", status: "online" },
        createdAt: dayjs().subtract(8, "hour").subtract(6, "minute").toDate(),
    },
    {
        id: "MSG-005",
        content: "Administator",
        author: { name: "Jie Yan", avatar: "/assets/avatar-8.png", status: "offline" },
        createdAt: dayjs().subtract(10, "hour").subtract(18, "minute").toDate(),
    },
];

export default function MySite(props) {
    const [site, setSite] = useState({});
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // const [editSiteOpen, setEditSiteOpen] = useState(false);

    useEffect(() => {
        let mounted = true;
        const token = JSON.parse(localStorage.getItem("user")).token;
        const subdomain = getSubdomain();
        const domain = getDomain();

        fetch(settings.api().mySite, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log(data);

                if (mounted) {
                    setSite(data);
                }
            });

        return () => {
            mounted = false;
        };
    }, []);

    const chartState = {
        series: [
            {
                name: "User Logins per month",
                data: [69, 93, 84, 102, 99, 79],
            },
        ],

        options: {
            markers: {
                size: 5,
            },
            chart: {
                type: "line",

                toolbar: {
                    tools: {
                        download: true,
                        selection: false,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: false,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            xaxis: {
                type: "category",
                categories: ["May", "June", "July", "August", "September", "October"],
            },
            yaxis: {
                labels: {
                    formatter: (val) => `${val.toLocaleString()}`,
                },
            },
            tooltip: {
                x: {
                    format: "MMMM", // Display full month name in tooltip
                },
            },
        },
    };

    return (
        <>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="space-between" column={{ xs: 12, md: 4 }}>
                                <Grid item>
                                    <Typography
                                        component="h1"
                                        variant="h4"
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <img
                                            src={
                                                JSON.parse(localStorage.getItem("userOrganizationInfo"))?.theme
                                                    ?.logoSmallUri
                                            }
                                            alt="Logo"
                                            style={{ marginRight: "8px" }} // optional: add spacing between image and text
                                        />
                                        {site.name ? site.name : "Some Site Name"}
                                    </Typography>
                                </Grid>
                                <Grid item align="center">
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            // className={classes.button}
                                            sx={{ margin: 1, width: "120px" }}
                                            startIcon={<EditIcon />}
                                            // onClick={() => setEditSiteOpen(true)}
                                            component={Link}
                                            to="/portal/site/edit"
                                        >
                                            Edit
                                        </Button>
                                        {/* <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            sx={{ margin: 1, width: "120px" }}
                                            startIcon={<AssessmentIcon />}
                                        >
                                            Report
                                        </Button> */}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end">
                                <Grid item xs >
                                    <div>
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <Typography
                                                    variant="h5"
                                                    component="div"
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    {site?.physicalAddress?.verified && (
                                                        <VerifiedIcon color="success" sx={{ mr: 1 }} />
                                                    )}
                                                    {site?.physicalAddress?.address1}
                                                </Typography>
                                                <Typography variant="h5">
                                                    {site?.physicalAddress?.city ? site?.physicalAddress?.city : ""},{" "}
                                                    {site?.physicalAddress?.provinceCode
                                                        ? site?.physicalAddress?.provinceCode
                                                        : ""}{" "}
                                                    {site?.physicalAddress?.postal
                                                        ? formatPostalCode(site?.physicalAddress?.postal)
                                                        : ""}
                                                </Typography>
                                                {site?.code && (
                                                    <Typography
                                                        color="inherit"
                                                        sx={{ whiteSpace: "nowrap" }}
                                                        variant="subtitle2"
                                                    >
                                                        <StoreIcon
                                                            fontSize="small"
                                                            style={{ verticalAlign: "middle" }}
                                                        />{" "}
                                                        {site?.code}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    color="inherit"
                                                    sx={{ whiteSpace: "nowrap" }}
                                                    variant="subtitle2"
                                                >
                                                    <PhoneIcon fontSize="small" style={{ verticalAlign: "middle" }} />{" "}
                                                    {formatPhone(site?.phonePrimary)}
                                                </Typography>
                                                {site.phoneSecondary && (
                                                    <Typography variant="body1">
                                                        Tel 2: {site.phoneSecondary}
                                                    </Typography>
                                                )}
                                                {site?.fax && (
                                                    <Typography
                                                        color="inherit"
                                                        sx={{ whiteSpace: "nowrap" }}
                                                        variant="subtitle2"
                                                    >
                                                        <FaxIcon fontSize="small" style={{ verticalAlign: "middle" }} />{" "}
                                                        {site?.fax}
                                                    </Typography>
                                                )}
                                                {site?.email && (
                                                    <Typography
                                                        color="inherit"
                                                        sx={{ whiteSpace: "nowrap" }}
                                                        variant="subtitle2"
                                                    >
                                                        <MailOutlineIcon
                                                            fontSize="small"
                                                            style={{ verticalAlign: "middle" }}
                                                        />{" "}
                                                        {site?.email}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="caption">Parent Organization:</Typography>
                                                <Typography variant="h6">{site?.organization?.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Box>
                                                    <Typography variant="caption">Services Available:</Typography>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexWrap: "wrap", // Ensures the chips wrap under the label
                                                            gap: "8px", // Space between the chips
                                                        }}
                                                    >
                                                        {site?.services?.map((s) => (
                                                            <Link key={s.name}>
                                                                <Chip
                                                                    sx={{
                                                                        borderRadius: "2px",
                                                                    }}
                                                                    label={s.name}
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                />
                                                            </Link>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                {/* <Grid item align="center">
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            // className={classes.button}
                                            sx={{ margin: 1, width: "120px" }}
                                            startIcon={<EditIcon />}
                                            // onClick={() => setEditSiteOpen(true)}
                                            component={Link}
                                            to="/portal/my-site/edit"
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            sx={{ margin: 1, width: "120px" }}
                                            startIcon={<AssessmentIcon />}
                                        >
                                            Report
                                        </Button>
                                    </div>
                                </Grid> */}
                            </Grid>
                        </CardContent>

                        {/* <CreateEditSite
                open={editSiteOpen}
                handleClose={() => setEditSiteOpen(false)}
                data={{ title: `${t("site.site_form.edit_site")}`, type: "edit" }}
            /> */}
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="Overview" />
                                <Tab label="Users" />
                                <Tab label="Activity" />
                                <Tab label="Settings" />

                                {/* <Tab label="OnDemand" />
                                <Tab label="CARSability" />
                                <Tab label="TAC" /> */}
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tabValue} index={0}>
                            <Grid container spacing={1} alignItems="stretch">
                                <Grid item md={8} xs={12}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent>
                                            <Typography variant="h6">User Login per month</Typography>
                                            <div id="chart">
                                                <ReactApexChart
                                                    options={chartState.options}
                                                    series={chartState.series}
                                                    type="line"
                                                    height={300}
                                                />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent>
                                            <Typography variant="h6">Recent Users</Typography>
                                            <List
                                                disablePadding
                                                sx={{
                                                    p: 1,
                                                    "& .MuiListItemButton-root": { borderRadius: 1 },
                                                    "& .MuiBadge-dot": {
                                                        border: "2px solid var(--mui-palette-background-paper)",
                                                        borderRadius: "50%",
                                                        bottom: "5px",
                                                        height: "12px",
                                                        right: "5px",
                                                        width: "12px",
                                                    },
                                                }}
                                                subheader={
                                                    <ListSubheader
                                                        disableGutters
                                                        sx={{
                                                            fontWeight: "bold",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <span>Name</span>
                                                        <span>Last sign-on</span>
                                                    </ListSubheader>
                                                }
                                            >
                                                {users.map((message, index) => (
                                                    <React.Fragment key={index}>
                                                        <ListItem disablePadding>
                                                            <ListItemButton sx={{ p: 0 }}>
                                                                <ListItemText
                                                                    disableTypography
                                                                    primary={
                                                                        <Typography noWrap variant="subtitle2">
                                                                            {message.author.name}
                                                                        </Typography>
                                                                    }
                                                                    secondary={
                                                                        <Typography
                                                                            color="text.secondary"
                                                                            noWrap
                                                                            variant="body2"
                                                                        >
                                                                            {message.content}
                                                                        </Typography>
                                                                    }
                                                                />
                                                                <Typography
                                                                    color="text.secondary"
                                                                    sx={{ whiteSpace: "nowrap" }}
                                                                    variant="caption"
                                                                >
                                                                    {dayjs(message.createdAt).fromNow()}
                                                                </Typography>
                                                            </ListItemButton>
                                                        </ListItem>
                                                        {index < users.length - 1 && <Divider />}
                                                    </React.Fragment>
                                                ))}
                                            </List>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            <UsersList />
                        </CustomTabPanel>
                        
                        <CustomTabPanel value={tabValue} index={2}>
                            
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={3}>
                            <Settings />
                        </CustomTabPanel>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
