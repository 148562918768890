import React, { useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    // TextField,
    Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { useEffect } from "react";
import settings from "../../../settings/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomTabPanel from "../../../components/CustomTabPanel";
import generateRandomPassword from "../../../Helpers/RandomPasswordGenerator";
import { getDomain, getSubdomain } from "../../../Helpers/getSubdomain";

const TextFieldContainer = styled("div")(({ theme }) => ({
    margin: theme.spacing(0, 0, 0),
    // width: "100%",
    // maxWidth: "250px",
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        // backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        // border: "1px solid",
        border: "1px solid rgb(133, 133, 133)",
        // borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        // borderColor: "#dee2e6",
        fontSize: 16,
        // width: "auto",
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
        "&:disabled": { borderColor: "#E0E3E7" },
    },
}));

const BootstrapSelect = styled(Select)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            // borderRadius: 4,
            borderColor: theme.palette.primary.main,
            // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const NewEditUser = ({ mode }) => {
    const subdomain = getSubdomain();
    const domain = getDomain();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    // let { state } = useLocation();
    // confirmPassword: "",
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        login: "",
        password: "",
        phoneMobile: "",
        phoneHome: "",
        phoneWork: "",
        phoneFax: "",
        postionName: "",
        profilePublic: false,
        siteId: 0,
        organizationId: "",
        localeId: 0,
        timeZoneId: 0,
    });

    const [organizationId] = useState(JSON.parse(localStorage.getItem("userOrganizationInfo")).id);
    const [sites, setSites] = useState([]);
    const [organizationInfo, setOrganizationInfo] = useState({});
    const [locales, setLocales] = useState([]);
    const [showError, setShowError] = useState(false);
    const [timezones, setTimezones] = useState([]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setUserInfo({ ...userInfo, organizationId: organizationId });
        setOrganizationInfo({ ...JSON.parse(localStorage.getItem("userOrganizationInfo")) });
        setLocales(JSON.parse(localStorage.getItem("userOrganizationInfo")).locales);
        fetch(settings.api().organizations + `/${organizationId}/sites`, {
            method: "GET",
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => {
                // console.log(res);

                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setSites(data);
            })
            .catch((e) => console.error(e));
        // fetch(settings.api().organizations + `/${JSON.parse(localStorage.getItem("organization")).id}/group-types`, {
        //     method: "GET",
        //     headers: {
        //         Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
        //     },
        // })
        //     .then((res) => {
        //         return res.json();
        //     })
        //     .then(
        //         (data) => console.log(data)
        //         // setGroupTypes(data)
        //     );
        fetchTimeZones();
        return () => {};
    }, []);
    const fetchTimeZones = () => {
        fetch(settings.api().timezones, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setTimezones(data);
            })
            .catch((error) => console.error(error));
    };

    const handleNewGroupInfoChange = (e) => {
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    };
    // console.log(newGroup);

    const createNewUser = () => {
        console.log(JSON.stringify(userInfo));
        // fetch(settings.api().organizations + `/${JSON.parse(localStorage.getItem("organization")).id}/users`, {
        fetch(settings.api(organizationId).organizationUsers, {
            method: "POST",
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userInfo),
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    navigate(
                        `/admin/organizations/${
                            JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId
                        }/users`
                    );
                } else {
                    setShowError(true);
                    return res.json();
                }
            })
            .then((data) => {
                console.log(data);
            })
            .catch((e) => console.error(e));
    };

    const updateUser = () => {};

    const createPassword = () => {
        const pass = generateRandomPassword();
        setUserInfo({ ...userInfo, password: pass });
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                {mode === "create" ? "Create New User" : "Edit User"}
            </Typography>

            <Typography variant="subtitle1">{mode === "create" ? "Create" : "Modify"} User Information</Typography>
            <Divider />
            <Box
                component="form"
                noValidate
                sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 2,
                    py: 2,
                }}
            >
                <FormControl variant="standard" fullWidth required={true}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        <strong>First Name</strong>
                    </InputLabel>
                    <BootstrapInput
                        name={"firstName"}
                        value={userInfo.firstName}
                        onChange={(e) => handleNewGroupInfoChange(e)}
                        // noValidate
                        // validated="false"
                    />
                </FormControl>
                <FormControl variant="standard" fullWidth required={true}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        <strong>Last Name</strong>
                    </InputLabel>
                    <BootstrapInput
                        name={"lastName"}
                        value={userInfo.lastName}
                        onChange={(e) => handleNewGroupInfoChange(e)}
                    />
                </FormControl>
                <FormControl variant="standard" fullWidth required={true}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        <strong>Email</strong>
                    </InputLabel>
                    <BootstrapInput
                        name={"email"}
                        type="email"
                        onChange={(e) => handleNewGroupInfoChange(e)}
                        value={userInfo.email}
                    />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        Login
                    </InputLabel>
                    <BootstrapInput name={"login"} value={userInfo.login} onChange={handleNewGroupInfoChange} />
                </FormControl>
                <FormControl variant="standard" fullWidth required={true}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        <strong>Password</strong>
                    </InputLabel>
                    <BootstrapInput
                        name={"password"}
                        type="password"
                        // onChange={(e) => handleNewGroupInfoChange(e)}
                        value={userInfo.password}
                        disabled
                    />
                </FormControl>
                <Button variant="outlined" onClick={createPassword} style={{ height: "43px", marginTop: "25px" }}>
                    Generate Password
                </Button>

                <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        Mobile Phone
                    </InputLabel>
                    <BootstrapInput
                        name={"phoneMobile"}
                        type="tel"
                        value={userInfo.phoneMobile}
                        onChange={(e) => handleNewGroupInfoChange(e)}
                    />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        Home Phone
                    </InputLabel>
                    <BootstrapInput
                        name={"phoneHome"}
                        type="tel"
                        value={userInfo.phoneHome}
                        onChange={(e) => handleNewGroupInfoChange(e)}
                    />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        Work Phone
                    </InputLabel>
                    <BootstrapInput
                        name={"phoneWork"}
                        type="tel"
                        onChange={(e) => handleNewGroupInfoChange(e)}
                        value={userInfo.phoneWork}
                    />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        Fax
                    </InputLabel>
                    <BootstrapInput type="tel" name={"phoneFax"} value={userInfo.phoneFax} />
                </FormControl>
                <FormControl variant="standard" fullWidth required={true}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        <strong>Position</strong>
                    </InputLabel>
                    <BootstrapInput
                        name={"positionName"}
                        onChange={(e) => handleNewGroupInfoChange(e)}
                        value={userInfo.positionName}
                    />
                </FormControl>
                <FormControlLabel
                    control={<Checkbox />}
                    label="Profile Public"
                    checked={userInfo?.profilePublic}
                    onChange={() =>
                        setUserInfo({
                            ...userInfo,
                            profilePublic: !userInfo.profilePublic,
                        })
                    }
                />
                <FormControl fullWidth required={true}>
                    <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                        <strong>Language</strong>
                    </InputLabel>
                    <BootstrapSelect value={userInfo.localeId} name={"localeId"} onChange={handleNewGroupInfoChange}>
                        <MenuItem disabled value={0}>
                            <em>--Select Language--</em>
                        </MenuItem>
                        {locales.map((l) => (
                            <MenuItem key={l.id} value={l.id}>
                                {l.name}
                            </MenuItem>
                        ))}
                    </BootstrapSelect>
                </FormControl>
                <FormControl fullWidth required={true}>
                    <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                        <strong>Time Zone</strong>
                    </InputLabel>
                    <BootstrapSelect
                        value={userInfo.timeZoneId}
                        name={"timeZoneId"}
                        onChange={handleNewGroupInfoChange}
                    >
                        <MenuItem disabled value={0}>
                            <em>--Select Time Zone--</em>
                        </MenuItem>
                        {/* <MenuItem value={1}>Test</MenuItem> */}
                        {timezones.map((t) => (
                            <MenuItem key={t.id} value={t.id}>
                                {t.title}
                            </MenuItem>
                        ))}
                    </BootstrapSelect>
                </FormControl>
                {/* <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        Profile Public
                    </InputLabel>
                    <BootstrapInput disabled name={"code"} value={userInfo.parentId} />
                </FormControl> */}
            </Box>
            <Typography variant="subtitle1">User's Site Information</Typography>
            <Divider />
            <Box
                component="form"
                noValidate
                sx={{
                    // display: "grid",
                    // gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 2,
                    py: 2,
                }}
            >
                <FormControl fullWidth>
                    <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input" required={true}>
                        <strong>Site</strong>
                    </InputLabel>
                    <BootstrapSelect value={userInfo.siteId} name={"siteId"} onChange={handleNewGroupInfoChange}>
                        <MenuItem disabled value={0}>
                            <em>--Select Site--</em>
                        </MenuItem>
                        {sites.map((s) => (
                            <MenuItem key={s.id} value={s.id}>
                                {s.code}- {s.name}
                            </MenuItem>
                        ))}
                    </BootstrapSelect>
                </FormControl>
            </Box>
            <Typography variant="subtitle1">User's Organization Information</Typography>
            <Divider />
            <Box
                component="form"
                noValidate
                sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 2,
                    py: 2,
                }}
            >
                <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="bootstrap-input" required={true}>
                        <strong>Name</strong>
                    </InputLabel>
                    <BootstrapInput
                        disabled
                        value={organizationInfo.name}
                        // name={"name"}
                        // // value={userInfo.name}
                        // onChange={(e) => handleNewGroupInfoChange(e)}
                    />
                </FormControl>
                <FormControl variant="standard" fullWidth required={true}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        <strong>Short Name</strong>
                    </InputLabel>
                    <BootstrapInput
                        disabled
                        // namename={"shortName"}
                        value={organizationInfo.shortName}
                        // onChange={(e) => handleNewGroupInfoChange(e)}
                    />
                </FormControl>
            </Box>
            <Divider />
            <div style={{ marginTop: "10px" }}>
                <Button
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    onClick={mode === "create" ? createNewUser : updateUser}
                >
                    {mode === "create" ? "Create" : "Update"}
                </Button>
                <Button variant="outlined" component={Link} to={`/admin/organizations/${organizationId}/users`}>
                    Cancel
                </Button>
            </div>
            <Dialog
                open={showError}
                onClose={() => setShowError(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Oops! Please fill in all required (*) fields.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setShowError(false)}>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default NewEditUser;
