import React from "react";

export default function OrgAdminDashboard() {

    return (
        <div>
            <h1>Organization Admin Dashboard</h1>
        </div>
    );
}
