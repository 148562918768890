const siteId = "siteId";

const adminSitesMenuItems = [
    {
        menuItem: true,
        icon: "dashboard",
        path: "/admin/sites/:{id}",
        label: "Dashboard",
    },
    {
        menuItem: false,
        label: "Services",
    },
    {
        menuItem: true,
        icon: "car_repair",
        path: "/admin/sites/:{id}/tire-quote",
        label: "Tire Quote",
        children: [
            {
                path: `/admin/sites/${siteId}/tire-quote/pricing`,
                label: "Pricing",
            },
            {
                path: `/admin/sites/${siteId}/tire-quote/featured`,
                label: "Featured",
            },
            {
                path: `/admin/sites/${siteId}/tire-quote/promotions`,
                label: "Promotions",
            },
            {
                path: `/admin/sites/${siteId}/tire-quote/stock`,
                label: "Stock",
            },
            {
                path: `/admin/sites/${siteId}/tire-quote/tpo`,
                label: "TPO",
            },
            {
                path: `/admin/sites/${siteId}/tire-quote/settings`,
                label: "Settings",
            },

        ],
    },
]

export default adminSitesMenuItems;