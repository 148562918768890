import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BootstrapInput from "../../components/BootstrapTextField";
import styled from "@emotion/styled";
import settings from "../../settings/api";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";

const BootstrapSelect = styled(Select)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            // borderRadius: 4,
            borderColor: theme.palette.primary.main,
            // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const Profile = () => {
    const domain = getDomain();
    const subdomain = getSubdomain();

    const [timezones, setTimezones] = useState([]);
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        login: "",
        password: "",
        phoneMobile: "",
        phoneHome: "",
        phoneWork: "",
        phoneFax: "",
        postionName: "",
        profilePublic: false,
        siteId: 0,
        organizationId: "",
        localeId: 0,
        timezoneId: 0,
    });
    const [updatePassword, setUpdatePassword] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        userId: 0,
    });
    const [locales, setLocales] = useState([]);
    const [user, setUser] = useState({});
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setLocales(JSON.parse(localStorage.getItem("userOrganizationInfo")).locales);
        fetchTimeZones();
        setUser(JSON.parse(localStorage.getItem("userOrganizationInfo")));
        fetchUser();
        return () => {};
    }, []);

    const handleUserInfoChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const updateUser = () => {
        // console.log(userInfo);
        // console.log(
        //     JSON.stringify({
        //         firstName: userInfo.firstName,
        //         lastName: userInfo.lastName,
        //         email: userInfo.email,
        //         login: userInfo.login,
        //         phoneMobile: userInfo.phoneMobile,
        //         phoneHome: userInfo.phoneHome,
        //         phoneWork: userInfo.phoneWork,
        //         phoneFax: userInfo.phoneFax,
        //         positionName: userInfo.positionName,
        //         profilePublic: userInfo.profilePublic,
        //         siteId: userInfo.site.id,
        //         organizationId: userInfo.organization.id,
        //         localeId: userInfo.locale.id,
        //         timezoneId: userInfo.timezone.id,
        //     })
        // );
        fetch(settings.api().users + `/${JSON.parse(localStorage.getItem("user")).userId}`, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify({
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                email: userInfo.email,
                login: userInfo.login,
                phoneMobile: userInfo.phoneMobile,
                phoneHome: userInfo.phoneHome,
                phoneWork: userInfo.phoneWork,
                phoneFax: userInfo.phoneFax,
                positionName: userInfo.positionName,
                profilePublic: userInfo.profilePublic,
                siteId: userInfo.site.id,
                organizationId: userInfo.organization.id,
                localeId: userInfo.locale.id,
                timezoneId: userInfo.timezone.id,
            }),
        })
            .then((res) => {
                console.log(res);
            })
            .catch((error) => console.error(error));
    };

    const fetchUser = () => {
        fetch(settings.api().users + `/${JSON.parse(localStorage.getItem("user")).userId}`, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setUserInfo({
                    ...data,
                    localeId: data.locale.id,
                    timezoneId: data.timezone.id,
                    siteId: data.site.name,
                    organizationId: data.organization.name,
                });
            });
    };

    const fetchTimeZones = () => {
        fetch(settings.api().timezones, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setTimezones(data);
            })
            .catch((error) => console.error(error));
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;

        setUpdatePassword({
            ...updatePassword,
            [name]: value,
        });
    };

    const handleUpdatePasswordChange = () => {
        if (
            updatePassword.currentPassword === "" ||
            updatePassword.newPassword === "" ||
            updatePassword.confirmPassword === ""
        ) {
            setShowError(true);
            return;
        }
        if (updatePassword.newPassword !== updatePassword.confirmPassword) {
            setShowError(true);
            return;
        }
        fetch(settings.api().users + `/${userInfo.id}/change-password`, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
            method: "PUT",
            body: JSON.stringify(),
        }).then((res) => {
            console.log(res);
            return res.json();
        });
    };

    return (
        <div>
            <div>
                <Typography variant="h4">Account</Typography>
            </div>
            <Card sx={{mt: 4}}>
                <CardHeader
                    avatar={<Avatar>{/* <StorefrontIcon fontSize="large" /> */}</Avatar>}
                    title={<Typography variant="h6">Basic Details</Typography>}
                />
                <CardContent sx={{ pb: 0 }}>
                    <Typography variant="subtitle1">My Information</Typography>
                    <Divider />
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            display: "grid",
                            gridTemplateColumns: { sm: "1fr 1fr" },
                            gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>First Name</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"firstName"}
                                value={userInfo.firstName}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Last Name</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"lastName"}
                                value={userInfo.lastName}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Email</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"email"}
                                type="email"
                                onChange={handleUserInfoChange}
                                value={userInfo.email}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Login
                            </InputLabel>
                            <BootstrapInput name={"login"} value={userInfo.login} onChange={handleUserInfoChange} />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Mobile Phone
                            </InputLabel>
                            <BootstrapInput
                                name={"phoneMobile"}
                                type="tel"
                                value={userInfo.phoneMobile}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Home Phone
                            </InputLabel>
                            <BootstrapInput
                                name={"phoneHome"}
                                type="tel"
                                value={userInfo.phoneHome}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Work Phone
                            </InputLabel>
                            <BootstrapInput
                                name={"phoneWork"}
                                type="tel"
                                onChange={handleUserInfoChange}
                                value={userInfo.phoneWork}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Fax
                            </InputLabel>
                            <BootstrapInput
                                type="tel"
                                name={"phoneFax"}
                                value={userInfo.phoneFax}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Position</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"positionName"}
                                onChange={handleUserInfoChange}
                                value={userInfo.positionName}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Profile Public"
                            checked={userInfo?.profilePublic}
                            onChange={() =>
                                setUserInfo({
                                    ...userInfo,
                                    profilePublic: !userInfo.profilePublic,
                                })
                            }
                        />
                        <FormControl fullWidth required={true}>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                <strong>Language</strong>
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.localeId}
                                name={"localeId"}
                                onChange={handleUserInfoChange}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select Language--</em>
                                </MenuItem>
                                {locales.map((l) => (
                                    <MenuItem key={l.id} value={l.id}>
                                        {l.name}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                        <FormControl fullWidth required={true}>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                <strong>Time Zone</strong>
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.timezoneId}
                                name={"timezoneId"}
                                onChange={handleUserInfoChange}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select Time Zone--</em>
                                </MenuItem>
                                {timezones.map((t) => (
                                    <MenuItem key={t.id} value={t.id}>
                                        {t.title}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                    </Box>
                    <div style={{ marginTop: "0px", marginBottom: "40px" }}>
                        <Button variant="outlined" style={{ marginRight: "10px" }} onClick={updateUser}>
                            Update
                        </Button>
                    </div>

                    <Typography variant="subtitle1">Change Password</Typography>
                    <Divider />
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            display: "grid",
                            gridTemplateColumns: { sm: "1fr 1fr" },
                            gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Current Password</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"currentPassword"}
                                onChange={handlePasswordChange}
                                value={updatePassword.currentPassword}
                            />
                        </FormControl>
                        <div></div>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>New Password</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"newPassword"}
                                onChange={handlePasswordChange}
                                value={updatePassword.newPassword}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Confirm Password</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"confirmPassword"}
                                onChange={handlePasswordChange}
                                value={updatePassword.confirmPassword}
                            />
                        </FormControl>
                    </Box>
                    <div style={{ marginTop: "0px", marginBottom: "40px" }}>
                        <Button
                            variant="outlined"
                            color="error"
                            style={{ marginRight: "10px" }}
                            onClick={handleUpdatePasswordChange}
                        >
                            Update Password
                        </Button>
                    </div>

                    <Typography variant="subtitle1">User's Site Information</Typography>
                    <Divider />
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            // gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl fullWidth>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input" required={true}>
                                <strong>Site</strong>
                            </InputLabel>
                            {/*  <BootstrapSelect value={userInfo.siteId} name={"siteId"} onChange={handleUserInfoChange}>
                        <MenuItem disabled value={0}>
                            <em>--Select Site--</em>
                        </MenuItem>
                       {sites.map((s) => (
                            <MenuItem key={s.id} value={s.id}>
                                {s.code}- {s.name}
                            </MenuItem>
                        ))} 
                    </BootstrapSelect>*/}
                            <BootstrapInput
                                disabled
                                value={userInfo?.siteId}
                                // name={"name"}
                                // // value={userInfo.name}
                                // onChange={(e) => handleNewGroupInfoChange(e)}
                            />
                        </FormControl>
                    </Box>
                    <Typography variant="subtitle1">User's Organization Information</Typography>
                    <Divider />
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            //     display: "grid",
                            //     gridTemplateColumns: { sm: "1fr 1fr" },
                            //     gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input" required={true}>
                                <strong>Name</strong>
                            </InputLabel>
                            <BootstrapInput
                                disabled
                                value={userInfo?.organizationId}
                                // name={"name"}
                                // // value={userInfo.name}
                                // onChange={(e) => handleNewGroupInfoChange(e)}
                            />
                        </FormControl>
                        {/* <FormControl variant="standard" fullWidth required={true}>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        <strong>Short Name</strong>
                    </InputLabel>
                    <BootstrapInput
                        disabled
                        // namename={"shortName"}
                        // value={organizationInfo.shortName}
                        // onChange={(e) => handleNewGroupInfoChange(e)}
                    />
                </FormControl> */}
                    </Box>
                </CardContent>
            </Card>

            <Dialog
                open={showError}
                onClose={() => setShowError(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Oops!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please ensure all password fields are filled, and New and Confirm passwords are the same.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setShowError(false)}>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Profile;
