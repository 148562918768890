import React, { useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Tooltip,
    Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// styling for available year selector
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Settings = () => {
    const [availableYearsSelected, setAvailableYearsSelected] = useState(0);
    const [availableYears] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15]);

    const [availableMakeSelected, setAvailableMakeSelected] = useState([]);
    const [availableMake] = useState(["Infiniti", "Nissan", "Mitsubushi"]);

    const [showAllMake, setShowAllMake] = useState(true);

    const handleAvailableMakeSelected = (event) => {
        const { value } = event.target;
        setAvailableMakeSelected(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    return (
        <div>
            
            <Grid
                container
                spacing={2}
                // sx={{ mt: 1}}
            >
                <Grid item md={6}>
                    <Card>
                        <CardHeader title={<Typography variant="h6">Some Setting</Typography>} />
                        <CardContent sx={{ height: "150px" }}></CardContent>
                    </Card>
                </Grid>
                <Grid item md={6}>
                    <Card>
                        <CardHeader title={<Typography variant="h6">That Setting</Typography>} />
                        <CardContent sx={{ height: "150px" }}></CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default Settings;
