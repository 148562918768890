import React from "react";

export default function Lessons() {
    // console.log("here");

    return (
        <div>
            <h1>Lessons</h1>
        </div>
    );
}
