import React from "react";
import TireQuote from '../../components/TireQuote/TireQuote'
import TireResults from "../../components/TireResults/TireResults";

export default function TireQuotePage() {
    return (
        <>
            <TireQuote />
            <TireResults />
        </>
    );
}
