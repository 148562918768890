/* eslint-disable import/no-named-as-default-member */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import { en } from "./translations/en";
// import { fr } from "./translations/fr";
const en = {
    profile: "Profile",
    subscription: "Subscription",
};
const fr = {
    profile: "Profil",
    subscription: "Abonnement",
};

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        fr: { translation: fr },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

// i18n.languages = ["en", "fr"];

export default i18n;
