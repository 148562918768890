import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Badge, Container, FormControl, Icon, Menu, MenuItem, Paper, Select, Tooltip } from "@mui/material";
import MenuByRole from "./menu/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Person2Icon from "@mui/icons-material/Person2";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PaymentsIcon from "@mui/icons-material/Payments";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getDomain, getSubdomain } from "../Helpers/getSubdomain";
import settings from "../settings/api";
import { connect } from "react-redux";
import { drawerClose, drawerToggle, updateMenuType } from "../settings/redux/componentsActions";
import formatPhone from "../Helpers/PhoneFormatter";
import PhoneIcon from "@mui/icons-material/Phone";
import formatPostalCode from "../Helpers/PostalCodeFormatter";
import { NotificationsPopover } from "./NotificationPopover";
import { usePopover } from "../Helpers/hooks/popover";
import { Bell as BellIcon } from "@phosphor-icons/react/dist/ssr/Bell";
import { GlobeHemisphereWest as GlobeHemisphereWestIcon } from "@phosphor-icons/react/dist/ssr/GlobeHemisphereWest";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    // width: `calc(${theme.spacing(7)} + 1px)`,
    width: 0,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    // marginTop: "75px"
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const AppBarManager = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    height: 36,
    marginTop: "139px",
    backgroundColor: "orange",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open
        ? {
              marginLeft: drawerWidth,
              width: `calc(100% - ${drawerWidth}px)`,
              transition: theme.transitions.create(["width", "margin"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
              }),
          }
        : {
              width: `calc(100% - 65px)`,
          }),
}));

const ToolbarManager = styled(Toolbar)({
    "@media all": {
        minHeight: 36,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const MenuProps = {
    PaperProps: {
        style: {
            backgroundColor: "rgba(50, 64, 82, 1)",
            color: "#e7ecfa",
        },
    },
};
function MiniDrawer(props) {
    const { children } = props;
    const theme = useTheme();
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [userInfo, setUserInfo] = useState({});
    const [portalInfo, setPortalInfo] = useState({});
    const [lang, setLang] = useState("EN");
    const [userType, setUserType] = useState("admin");
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState("");

    const [anchorEl, setAnchorEl] = useState(null);
    const openAccountMenu = Boolean(anchorEl);

    const [structureMenu, setStructureMenu] = useState({});
    const [currentView, setCurrentView] = useState("User View");

    const handleDrawerOpen = () => {
        // setOpen(true);
        props.openAction();
    };
    const handleDrawerClose = () => {
        // setOpen(false);
        props.closeAction();
    };

    const handleOpenAccountMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const menuType = localStorage.getItem("menuType");
        setUserType(menuType);
        if (menuType === "sitesAdmin") {
            // used for testing admin without an admin account
            // setUserType(menuType);
            // getAdminOrganizations();
            setCurrentView("Site Admin");
        } else if (menuType === "orgAdmin") {
            setCurrentView("Portal Admin");
        } else if (menuType === "multiOrgAdmin") {
            setCurrentView("System Admin");
        } else {
            setCurrentView("User View");
        }
    }, [localStorage.getItem("menuType")]);

    const handleLogout = () => {
        localStorage.clear();
        navigate("/login");
    };

    useEffect(() => {
        getMenusStructure();
        setUserInfo(JSON.parse(localStorage.getItem("user")));
        setPortalInfo(JSON.parse(localStorage.getItem("userOrganizationInfo")));
        return () => {};
    }, []);

    const handleLanguageChange = () => {
        if (lang === "EN") {
            setLang("FR");
            i18n.changeLanguage("fr");
        } else {
            setLang("EN");
            i18n.changeLanguage("en");
        }
    };

    const handleChangeToStandard = () => {
        props.updateMenuType("user");
        localStorage.setItem("menuType", "user");
        localStorage.removeItem("organization");
        navigate("/portal");
        handleClose();
    };

    const handleChangeToAdmin = () => {
        props.updateMenuType("sitesAdmin");
        localStorage.setItem("menuType", "sitesAdmin");
        navigate("/admin/sites/id");
        handleClose();
    };

    const handleChangeToOrgAdmin = () => {
        handleClose();
        props.updateMenuType("orgAdmin");
        localStorage.setItem("menuType", "orgAdmin");
        navigate("/admin/organizations/id");
    };

    const handleChangeToMultiOrgAdmin = () => {
        handleClose();
        props.updateMenuType("multiOrgAdmin");
        localStorage.setItem("menuType", "multiOrgAdmin");
        navigate("/admin/global");
    };

    const getAdminOrganizations = () => {
        // console.log("here");
        // const organizationId = JSON.parselocalStorage.getItem("organization").id;
        const domain = getDomain();
        const subdomain = getSubdomain();
        fetch(settings.api().organizations, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setOrganizations(data);

                // console.log(localStorage.getItem("AdminSelectedOrg") === null);

                // if organization has not yet been set
                if (localStorage.getItem("AdminSelectedOrg") === null) {
                    // console.log("if");
                    setSelectedOrganization(JSON.stringify(data[0]));
                    // localStorage.setItem("userOrganizationInfo", JSON.stringify(data[0]));
                    localStorage.setItem("AdminSelectedOrg", JSON.stringify(data[0]));
                } else {
                    // console.log("here");
                    setSelectedOrganization(localStorage.getItem("AdminSelectedOrg"));
                }
            });
    };

    const handleOrganizationChangeInAdminMenu = (e) => {
        // console.log(e.target.value, selectedOrganization);

        setSelectedOrganization(e.target.value);
        localStorage.setItem("AdminSelectedOrg", e.target.value);
        // console.log(location.pathname);
        let newURL = location.pathname;
        navigate(newURL, { replace: true });
        navigate(0);
    };

    const getMenusStructure = () => {
        const token = JSON.parse(localStorage.getItem("user")).token;
        const domain = getDomain();
        const subdomain = getSubdomain();
        return fetch(settings.api().structure, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setStructureMenu(data);
            })
            .catch((err) => console.error(err));
    };

    const handleViewChange = (view) => {
        switch (view) {
            case "User View":
                setCurrentView("User View");
                handleChangeToStandard();
                break;
            case "Site Admin":
                setCurrentView("Site Admin");
                handleChangeToAdmin();
                break;
            case "Portal Admin":
                setCurrentView("Portal Admin");
                handleChangeToOrgAdmin();
                break;
            case "System Admin":
                setCurrentView("System Admin");
                handleChangeToMultiOrgAdmin();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <AppBar
                color="transparent"
                position="fixed"
                sx={{ height: "75px", boxShadow: "none", bgcolor: userType === "admin" ? "#2A2F38" : "white" }}
            >
                <Toolbar>
                    <img
                        src={portalInfo.theme?.logoSmallUri}
                        alt={portalInfo?.organizationName}
                        style={{ marginRight: "10px" }}
                    />
                    <Typography variant="h6" noWrap component="div">
                        {portalInfo?.portalFullName}
                    </Typography>
                    <Divider orientation="vertical" flexItem style={{ margin: "15px 35px" }} />
                    <div style={{ marginRight: "45px" }}>
                        <Typography
                            color="inherit"
                            sx={{ whiteSpace: "nowrap" }}
                            variant="subtitle1"
                            fontWeight={"bold"}
                        >
                            {userInfo?.site?.name}
                        </Typography>{" "}
                        <Typography color="text.secondary" variant="body2">
                            {userInfo?.site?.code}
                        </Typography>
                    </div>
                    <Box sx={{ flexGrow: 1, my: 2 }}>
                        <Typography sx={{ whiteSpace: "nowrap" }} color="text.secondary" variant="body2">
                            <PhoneIcon sx={{ fontSize: 16 }} style={{ verticalAlign: "middle" }} />{" "}
                            {formatPhone(userInfo?.site?.phonePrimary)}
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                            {userInfo?.site?.address?.address1}{" "}
                            {userInfo?.site?.address?.address2 && <>, {userInfo?.site?.address?.address2}</>}
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                            {userInfo?.site?.address?.city}{" "}
                            {userInfo?.site?.address?.postal && formatPostalCode(userInfo?.site?.address?.postal)}
                        </Typography>
                    </Box>
                    <Badge
                        color="error"
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        sx={{
                            "& .MuiBadge-standard": {
                                right: "8px",
                                top: "6px",
                            },
                        }}
                        // variant="string"
                        badgeContent={lang}
                    >
                        <IconButton sx={{ color: "text.primary" }} onClick={handleLanguageChange}>
                            <GlobeHemisphereWestIcon />
                        </IconButton>
                    </Badge>
                    <NotificationsButton />
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenAccountMenu}
                            color="inherit"
                        >
                            <AccountCircleIcon />
                        </IconButton>
                        <Menu
                            open={openAccountMenu}
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            onClose={handleClose}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        width: "300px",
                                        "&:before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0,
                                        },
                                    },
                                },
                            }}
                        >
                            <Typography variant="subtitle1" sx={{ mx: 2 }}>
                                {structureMenu?.accountMenu?.title}
                            </Typography>
                            <Typography variant="body2" sx={{ mx: 2, mb: 2 }} color="text.secondary">
                                {structureMenu?.accountMenu?.subTitle}
                            </Typography>
                            <Divider />
                            {structureMenu?.accountMenu?.menuItems.map((i) => (
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        i.label && props.updateMenuType("user");
                                        i.label && localStorage.setItem("menuType", "user");
                                        // i.label && localStorage.removeItem("organization");
                                        navigate(i.path);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon fontSize="small" color="primary">
                                            {i.icon}
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText>{i.label}</ListItemText>
                                </MenuItem>
                            ))}                            
                            <Divider />
                            {structureMenu?.accountMenu?.viewItems.map((i) => (
                                <MenuItem onClick={() => handleViewChange(i.label)} disabled={i.label === currentView}>
                                    <ListItemIcon>
                                        <Icon fontSize="small" color="primary">
                                            {i.icon}
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {i.label} {i.label === currentView && "(current)"}
                                    </ListItemText>
                                </MenuItem>
                            ))}                         
                            <Divider />
                            <MenuItem onClick={handleLogout}>
                                <ListItemText sx={{ textAlign: "center" }}>Logout</ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: "flex" }}>
                <AppBar open={props.open} sx={{ height: "64px", mt: "75px" }} position="fixed">
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(props.open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Welcome {userInfo.firstName} {userInfo.lastName}
                            {userInfo?.role?.code === "ADMIN" ||
                                (userInfo?.role?.code === "MANAGER" && (
                                    <Typography variant="caption" component="span" sx={{ ml: 1 }}>
                                        ({userInfo?.role?.role})
                                    </Typography>
                                ))}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Drawer
                    sx={{
                        "& .MuiPaper-root": {
                            scrollbarWidth: "none", // Firefox
                            "&::-webkit-scrollbar": {
                                display: "none", // Webkit browsers (Chrome, Safari)
                            },
                        },
                    }}
                    variant="permanent"
                    open={props.open}
                    PaperProps={{
                        sx: {
                            backgroundColor: "#182434",
                            color: "#949ca7",
                        },
                    }}
                >
                    <DrawerHeader
                        style={
                            {
                                // height: "100px"
                            }
                        }
                    >
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon style={{ color: "#949ca7" }} />
                            ) : (
                                <ChevronLeftIcon style={{ color: "#949ca7" }} />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    {userType === "admin" && props.open ? (
                        <FormControl sx={{ m: 1, width: "100%" }}>
                            <Select
                                displayEmpty
                                disableUnderline
                                value={selectedOrganization}
                                onChange={(e) => handleOrganizationChangeInAdminMenu(e)}
                                variant="standard"
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                                sx={{
                                    backgroundColor: "#182434",
                                    borderRadius: "5px",
                                    padding: "0 10px",
                                    color: "#727982",
                                    width: "225px",

                                    "& .MuiSvgIcon-root": {
                                        color: "#727982",
                                    },
                                }}
                            >
                                <MenuItem disabled>Organizations</MenuItem>
                                {organizations.map((a) => (
                                    <MenuItem key={a.id} value={JSON.stringify(a)}>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography sx={{ fontSize: "1rem", color: "#949ca7" }}>
                                                {a.name}
                                            </Typography>
                                            <Typography sx={{ fontSize: "0.875rem", color: "#727982" }}>
                                                {a.shortName}
                                            </Typography>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        userType === "admin" && !props.open && <div style={{ height: "74px" }} />
                    )}

                    {structureMenu?.version && <MenuByRole userType={userType} structureMenu={structureMenu} />}
                </Drawer>
                <Paper
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        minHeight: "100vh",
                        // backgroundColor: userType === "admin" ? "#3A414A" : null,
                        // color: userType === "admin" ? "#B0B8C1" : null,
                    }}
                >
                    <DrawerHeader style={{ height: userType === "user" ? "139px" : "160px" }} />
                    {userType !== "user" && (
                        <AppBarManager open={props.open} elevation={0} position="fixed">
                            <ToolbarManager>
                                <Typography variant="subtitle1" sx={{ fontWeight: 700, flexGrow: 1 }}>
                                    SITE: Nissan Whitby (Nissan)
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                    {userType === "sitesAdmin"
                                        ? "SITE ADMINISTRATION"
                                        : userType === "orgAdmin"
                                        ? "PORTAL ADMINISTRATION"
                                        : "SYSTEM ADMINISTRATION"}
                                </Typography>
                            </ToolbarManager>
                        </AppBarManager>
                    )}

                    <Container maxWidth="xl">{children}</Container>
                </Paper>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        open: state.componentsReducer.drawerOpen,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openAction: () => {
            dispatch(drawerToggle());
        },
        closeAction: () => {
            dispatch(drawerClose());
        },
        updateMenuType: (menuType) => {
            dispatch(updateMenuType(menuType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);

function NotificationsButton() {
    const popover = usePopover();

    return (
        <React.Fragment>
            <Tooltip title="Notifications">
                <Badge
                    color="error"
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        "& .MuiBadge-standard": {
                            right: "8px",
                            top: "6px",
                        },
                    }}
                    // variant="dot"
                    badgeContent={4}
                >
                    <IconButton sx={{ color: "text.primary" }} onClick={popover.handleOpen} ref={popover.anchorRef}>
                        <BellIcon />
                    </IconButton>
                </Badge>
            </Tooltip>
            <NotificationsPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                open={popover.open}
            />
        </React.Fragment>
    );
}
