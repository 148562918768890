import React from "react";

export default function OrgAdminSites() {

    return (
        <div>
            <h1>Org Admin Sites</h1>
        </div>
    );
}
