import React from "react";
import { CircularProgress } from "@mui/material";

const LoadingSpinner = () => {
    return (
        <div sx={{ display: "flex", alignItem: "center", justifyContent: "center", hieght: "300px" }}>
            <CircularProgress />
        </div>
    );
};

export default LoadingSpinner;
