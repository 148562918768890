import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Switch,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const iframeCode = `<iframe
    id="content-iframe"
    src="https://tire-quote.tirelance.com"
    width="100%"
    frameborder="0"
    style="border: none; margin-top: 20px; overflow: hidden"
    scrolling="no"
/>`;

const iframeJavascriptCode = `window.addEventListener('message', function(event) {
    if (event.origin === 'https://tire-quote.tirelance.com') {
        const iframe = document.getElementById('content-iframe');
        if (iframe) {
            iframe.height = event.data + 'px';
        }
    }
});`;

// styling for available year selector
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// const availableYears = ["2025", "2024", "2023", "2022", "2021", "2020"];

const WidgetSetting = () => {
    const [domainName, setDomainName] = useState("example.com");
    const [keyValue, setKeyValue] = useState("sdhfwieurh31143u4hierwuhr39urfwlfsjfqsdfe3=sadascgb1");
    const [openSnackbar, setOpenSnackbar] = useState({ open: false, message: "" });
    const [openViewInstructions, setOpenViewInstructions] = useState(false);

    const [availableYearsSelected, setAvailableYearsSelected] = useState(0);
    const [availableYears] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15]);

    const [availableMakeSelected, setAvailableMakeSelected] = useState([]);
    const [availableMake] = useState(["Infiniti", "Nissan", "Mitsubushi"]);

    const [showAllMake, setShowAllMake] = useState(true);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleCopy = () => {
        navigator.clipboard
            .writeText(keyValue)
            .then(() => {
                setOpenSnackbar({ open: true, message: "Client key copied." });
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
            });
    };

    const handleCopyIframeCode = () => {
        navigator.clipboard
            .writeText(iframeCode)
            .then(() => {
                setOpenSnackbar({ open: true, message: "iframe code copied." });
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
            });
    };
    const handleCopyIframeJavaScriptCode = () => {
        navigator.clipboard
            .writeText(iframeJavascriptCode)
            .then(() => {
                setOpenSnackbar({ open: true, message: "JavaScript code copied." });
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
            });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleAvailableMakeSelected = (event) => {
        const { value } = event.target;
        setAvailableMakeSelected(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    return (
        <div>
            <Card>
                <CardHeader title={<Typography variant="h6">Widgets</Typography>} />
                <CardContent>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 0, gap: 1 }}>
                        <FormControlLabel
                            sx={{ ml: 0 }}
                            value="end"
                            control={<Switch color="primary" />}
                            label="Enable Widget"
                            labelPlacement="start"
                        />
                        <Button variant="outlined" onClick={() => setOpenViewInstructions(true)}>
                            View Instructions
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", p: 0, mt: 2, gap: 1 }}>
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="Host Site Domain"
                            variant="outlined"
                            fullWidth
                            value={domainName}
                        />
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="Client Key"
                            variant="outlined"
                            fullWidth
                            disabled
                            value={keyValue}
                        />
                        <IconButton sx={{ p: 1 }}>
                            <ContentCopyIcon onClick={handleCopy} />
                        </IconButton>
                    </Box>
                </CardContent>
            </Card>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openSnackbar.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={openSnackbar.message}
            />
            <Dialog
                fullScreen={fullScreen}
                open={openViewInstructions}
                onClose={() => setOpenViewInstructions(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Instructions to add iFrame Content to your website
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add the micro-site to your website, please add the following iframe code to the pager where
                        you would like to load the widget:
                    </DialogContentText>
                    <TextField
                        sx={{ mt: 2 }}
                        label="HTML Code"
                        multiline
                        defaultValue="Default Value"
                        size="small"
                        disabled
                        fullWidth
                        value={iframeCode}
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, mb: 2 }}>
                        <Button variant="outlined" startIcon={<ContentCopyIcon />} onClick={handleCopyIframeCode}>
                            Copy iFrame Code
                        </Button>
                    </Box>

                    <DialogContentText>
                        Add the following javascript code to either the bottom of your page or to an external script
                        file linked to by your site:
                    </DialogContentText>
                    <TextField
                        sx={{ mt: 2 }}
                        label="JavaScrip Code"
                        multiline
                        defaultValue="Default Value"
                        size="small"
                        disabled
                        fullWidth
                        value={iframeJavascriptCode}
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, mb: 2 }}>
                        <Button
                            variant="outlined"
                            startIcon={<ContentCopyIcon />}
                            onClick={handleCopyIframeJavaScriptCode}
                        >
                            Copy JavaScript Code
                        </Button>
                    </Box>

                    <DialogContentText>
                        <strong>
                            PLEASE NOTE: For the iframe to load securely, the website domain must match the widget
                            domain.
                        </strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenViewInstructions(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default WidgetSetting;
