import React, { Suspense, lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Portal, { PortalLayout } from "../layout/Portal/Portal";
import Error404 from "../pages/404";
import Dashboard from "../pages/Dashboard/Dashboard";
import PrivateRoute from "../useAuth";
import Groups from "../pages/AdminPages/Groups/Groups";
import NewGroup from "../pages/AdminPages/Groups/NewGroup";
import Sites from "../pages/AdminPages/Sites/Sites";
import NewEditUser from "../pages/AdminPages/Users/NewEditUser";
import Cases from "../pages/StandardPages/Cases";
import Lessons from "../pages/StandardPages/Lessons";
import Assessments from "../pages/StandardPages/Assessments";
import MySite from "../pages/StandardPages/MySite";
import LoadingSpinner from "../components/Spinner";
import NewGroupType from "../pages/AdminPages/GroupTypes/NewGroupType";
import Site from "../pages/AdminPages/Sites/Site";
import ViewUser from "../pages/AdminPages/Users/ViewUser";
import Profile from "../pages/StandardPages/Profile";
import NewPageTmp from "../pages/AdminPages/SystemAdmin/tmp";
import { UsersList } from "../pages/AdminPages/Users/UsersList";
// import MySiteEdit from "../pages/StandardPages/MySiteEdit";
// import TireQuote from '../components/TireQuote'
import TireQuotePage from "../pages/TireQuotePage";
import ProductDetailPage from "../pages/StandardPages/TireDetails";
import { EditSiteForm } from "../pages/AdminPages/Users/EditSiteForm";
import UserDetails from "../pages/AdminPages/Users/UserDetails";
import NewAssessments from "../pages/StandardPages/NewAssessments";
import AccountLayout from "../pages/StandardPages/Account";
import Notifications from "../pages/StandardPages/Notifications";
import AdminDashboard from "../pages/AdminPages/AdminDashboard";
import TireQuote from "../pages/AdminPages/TireQuote/TireQuote";
import Pricing from "../pages/AdminPages/TireQuote/Pricing";
import Featured from "../pages/AdminPages/TireQuote/Featured";
import Promotions from "../pages/AdminPages/TireQuote/Promotions";
import Stock from "../pages/AdminPages/TireQuote/Stock";
import TPO from "../pages/AdminPages/TireQuote/TPO";
import Settings from "../pages/AdminPages/TireQuote/Settings";
import Orders from "../pages/TireQuotePage/Orders";
import Vehicles from "../pages/TireQuotePage/Vehicles";
import Tires from "../pages/TireQuotePage/Tires";
import Wheels from "../pages/TireQuotePage/Wheels";
import Packages from "../pages/TireQuotePage/Packages";
import Accessories from "../pages/TireQuotePage/Accessories";
import Reports from "../pages/TireQuotePage/Reports";

// sites admin
import SitesAdminDashboard from "../pages/SitesAdmin/Dashboard";
import OrgAdminDashboard from "../pages/OrgAdmin/Dashboard";
import OrgAdminSites from "../pages/OrgAdmin/Sites/Sites";
import GroupTypes from "../pages/AdminPages/GroupTypes/GroupTypes";
import OrgAdminTrainingSettings from "../pages/OrgAdmin/Training/Settings";
import OrgAdminAssessmentSettings from "../pages/OrgAdmin/Assessment/Settings";
import OrgAdminTACSettings from "../pages/OrgAdmin/TAC/Settings";
import MultiOrgAdminDashboard from "../pages/MultiOrgAdmin/Dashboard";
import MultiOrgList from "../pages/MultiOrgAdmin/Organizations";
import OrgAdminTrainingCourses from "../pages/OrgAdmin/Training/Courses/Courses";
import OrgAdminTrainingCourseCategories from "../pages/OrgAdmin/Training/Courses/CourseCategories";
import OrgAdminTrainingLessons from "../pages/OrgAdmin/Training/Courses/Lessons";
import Order from "../pages/TireQuotePage/Order";
import Versions from "../pages/StandardPages/Versions";

const DashboardPage = lazy(() => import("../pages/Dashboard/Dashboard"));

export default function AppRouter() {
    // useValidateDomainTenant();
    return (
        <Routes>
            {/* <Route path="/" element={<Home />} exact /> */}
            <Route path="login" element={<Login />} exact />
            <Route
                path=""
                element={
                    <PrivateRoute>
                        <Portal />
                    </PrivateRoute>
                }
            >
                <Route path="sites" element={<Dashboard />} />
                <Route path="sites/:siteId" element={<Dashboard />} />
                <Route path="sites/:siteId/users" element={<Dashboard />} />
                <Route path="users" element={<Dashboard />} />
                <Route path="users/:userId" element={<Dashboard />} />
                <Route path="users/:userId/sites" element={<Dashboard />} />
                <Route path="users/:userId/sites/siteId" element={<Dashboard />} />
            </Route>
            <Route
                path="portal"
                element={
                    <PrivateRoute>
                        <Portal />
                    </PrivateRoute>
                }
            >
                <Route path="countries" element={<Dashboard />} />
                <Route path="countries/:countryId" element={<Dashboard />} />
                <Route path="locales" element={<Dashboard />} />
                <Route path="locales/:localId" element={<Dashboard />} />
                <Route path="settings" element={<Dashboard />} />
                <Route path="permissions/user-organization-roles" element={<Dashboard />} />
                <Route path="permissions/user-site-roles" element={<Dashboard />} />
                <Route path="timezones" element={<Dashboard />} />
                <Route path="timezones/:timezoneId" element={<Dashboard />} />
            </Route>
            <Route
                path="admin"
                element={
                    <PrivateRoute>
                        <Portal />
                    </PrivateRoute>
                }
            >
                <Route path="" element={<Dashboard />} />
                <Route path="organizations" element={<Dashboard />} />
                <Route path="organizations/:organizationId" element={<Dashboard />} />
                <Route path="organizations/:organizationId/groups" element={<Groups />} />
                <Route path="organizations/:organizationId/groups/new" element={<NewGroup />} />
                <Route path="organizations/:organizationId/groups/:groupId" element={<Dashboard />} />
                <Route path="organizations/:organizationId/group-types" element={<Dashboard />} />
                <Route path="organizations/:organizationId/group-types/:groupTypesId" element={<Dashboard />} />
                <Route path="organizations/:organizationId/sites" element={<Dashboard />} />
                <Route path="organizations/:organizationId/sites/:siteId" element={<Dashboard />} />
                <Route path="organizations/:organizationId/sites/:siteId/users" element={<Dashboard />} />
                <Route path="organizations/:organizationId/sites/:siteId/users/:userId" element={<Dashboard />} />
                <Route path="organizations/:organizationId/sites/:siteId/addresses" element={<Dashboard />} />
                <Route
                    path="organizations/:organizationId/sites/:siteId/addresses/:addressId"
                    element={<Dashboard />}
                />
                <Route path="organizations/:organizationId/site-categories" element={<Dashboard />} />
                <Route path="organizations/:organizationId/site-categories/:siteCategoryId" element={<Dashboard />} />
                <Route path="organizations/:organizationId/user-categories/:userCategoryId" element={<Dashboard />} />
                <Route path="organizations/:organizationId/users" element={<Dashboard />} />
                <Route path="organizations/:organizationId/users/:userId" element={<Dashboard />} />
                <Route path="organizations/:organizationId/users/:userId/user-categories" element={<Dashboard />} />
                <Route
                    path="organizations/:organizationId/users/:userId/user-categories/:userCategoryId"
                    element={<Dashboard />}
                />
                <Route path="organizations/:organizationId/users/:userId/roles" element={<Dashboard />} />
                <Route path="organizations/:organizationId/users/:userId/roles/:roleId" element={<Dashboard />} />
            </Route>
            <Route path="*" element={<Error404 />} exact />
        </Routes>
    );
}

export const routes = [
    {
        index: true,
        element: <Home />,
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "portal",
        element: (
            <Suspense fallback={<LoadingSpinner />}>
                <PortalLayout>
                    <Outlet />
                </PortalLayout>
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: <DashboardPage />,
            },
            {
                path: "tires",
                children: [
                    {
                        index: true,
                        element: <TireQuotePage />,
                    },
                    {
                        path: "orders",
                        element: <Orders />,
                    },
                    {
                        path: "orders/:id",
                        element: <Order />,
                    },
                    {
                        path: "vehicles",
                        element: <Vehicles />,
                    },
                    {
                        path: "tires",
                        element: <Tires />,
                    },
                    {
                        path: "wheels",
                        element: <Wheels />,
                    },
                    {
                        path: "packages",
                        element: <Packages />,
                    },
                    {
                        path: "accessories",
                        element: <Accessories />,
                    },
                    {
                        path: "reports",
                        element: <Reports />,
                    },
                ],
            },
            {
                path: "lessons",
                element: <Lessons />,
            },
            {
                path: "assessments",
                children: [
                    {
                        index: true,
                        element: <Assessments />,
                    },
                    {
                        path: "new",
                        element: <NewAssessments />,
                    },
                ],
            },
            {
                path: "cases",
                element: <Cases />,
            },
            {
                path: "site",
                children: [
                    {
                        index: true,
                        element: <MySite />,
                    },
                    {
                        path: "edit",
                        element: <EditSiteForm />,
                    },
                    {
                        path: "add-member",
                        element: <EditSiteForm />,
                    },
                    {
                        path: "users/:id",
                        element: <UserDetails />,
                    },
                ],
            },
            {
                path: "account",
                // element: <Profile />,
                element: (
                    <AccountLayout>
                        <Profile />
                    </AccountLayout>
                ),
            },
            {
                path: "notifications",
                element: (
                    <AccountLayout>
                        <Notifications />
                    </AccountLayout>
                ),
            },
            {
                path: "user",
                element: <UserDetails />,
            },
            {
                path: "/portal/versions",
                element: <Versions />   
            },
            {
                path: "*",
                element: <Error404 />,
            },
        ],
    },
    {
        path: "admin/sites/:id",
        element: (
            <Suspense fallback={<LoadingSpinner />}>
                <PortalLayout>
                    <Outlet />
                </PortalLayout>
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: <SitesAdminDashboard />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "pricing",
                        element: <Pricing />,
                    },
                    {
                        path: "featured",
                        element: <Featured />,
                    },
                    {
                        path: "promotions",
                        element: <Promotions />,
                    },
                    {
                        path: "stock",
                        element: <Stock />,
                    },
                    {
                        path: "tpo",
                        element: <TPO />,
                    },
                    {
                        path: "settings",
                        element: <Settings />,
                    },
                ],
            },
        ],
    },
    {
        path: "admin/organizations/:id",
        element: (
            <Suspense fallback={<LoadingSpinner />}>
                <PortalLayout>
                    <Outlet />
                </PortalLayout>
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: <OrgAdminDashboard />,
            },
            {
                path: "sites",
                children: [
                    {
                        path: "list",
                        element: <OrgAdminSites />,
                    },
                    {
                        path: "groups",
                        element: <Groups />,
                    },
                    {
                        path: "group-types",
                        element: <GroupTypes />,
                    },
                ],
            },
            {
                path: "users",
                children: [
                    {
                        path: "list",
                        element: <UsersList />,
                    },
                ],
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "settings",
                        element: <Settings />,
                    },
                ],
            },
            {
                path: "lms",
                children: [
                    {
                        path: "courses",
                        element: <OrgAdminTrainingCourses />,
                    },
                    {
                        path: "lessons",
                        element: <OrgAdminTrainingLessons />,
                    },
                    {
                        path: "course-categories",
                        element: <OrgAdminTrainingCourseCategories />,
                    },
                    {
                        path: "settings",
                        element: <OrgAdminTrainingSettings />,
                    },
                ],
            },
            {
                path: "assessments",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminAssessmentSettings />,
                    },
                ],
            },
            {
                path: "tac",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminTACSettings />,
                    },
                ],
            },
        ],
    },
    {
        path: "admin/global",
        element: (
            <Suspense fallback={<LoadingSpinner />}>
                <PortalLayout>
                    <Outlet />
                </PortalLayout>
            </Suspense>
        ),
        children: [
            {
                index: true,
                element: <MultiOrgAdminDashboard />,
            },
            {
                path: "organizations",
                children: [
                    {
                        path: "list",
                        element: <MultiOrgList />,
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        element: <Error404 />,
    },
];
