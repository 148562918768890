import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// import { FilterButton, FilterPopover, useFilterContext } from "@/components/core/filter-button";
// import { Option } from "@/components/core/option";

import { useCustomersSelection } from "./customers-selection-context";
import { useLocation, useNavigate } from "react-router-dom";
import { FilterButton, FilterPopover, useFilterContext } from "../FilterButton";
import { Option } from "../option";
import { InputLabel, MenuItem } from "@mui/material";

export function DataTableFilters({ filters, initialSortDir = "", sorts = [] }) {
    const location = useLocation();
    const navigate = useNavigate();
    const selection = useCustomersSelection();
    const [filterValues, setFilterValues] = useState({});
    const [sortDir, setSortDir] = useState(initialSortDir);
    const [hasFilters, setHasFilters] = useState(false);
    const [statuses, setStatuses] = useState({});

    useEffect(() => {
        const s = filters?.find((f) => f.field === "status");
        if (s) {
            setStatuses(s);
            setFilterValues({ ...filterValues, status: s.values[0] });
        }

        // Parse the search parameters from the URL on component load
        const searchParams = new URLSearchParams(location.search);

        // const initialFilters = {
        //     name: searchParams.get("Name") || "",
        //     email: searchParams.get("Email") || "",
        //     role: searchParams.get("Role") || "",
        //     status: searchParams.get("Status") || "",
        // };
        // Dynamically create the initialFilters based on available fields in `filters`
        const initialFilters = filters.reduce((acc, filter) => {
            acc[filter.field] = searchParams.get(filter.field) || "";
            return acc;
        }, {});
        setFilterValues(initialFilters);

        const initialSortDir = searchParams.get("sortDir") || sorts[0]?.field;
        setSortDir(initialSortDir);

        return () => {};
    }, []);

    const updateSearchParams = React.useCallback(
        (newFilters, newSortDir) => {
            const searchParams = new URLSearchParams();

            if (newSortDir === "lastSignon") {
                searchParams.set("sortDir", newSortDir);
            }

            if (newFilters.status) {
                searchParams.set("Status", newFilters.status);
            }

            if (newFilters.email) {
                searchParams.set("Email", newFilters.email);
            }

            if (newFilters.name) {
                searchParams.set("Name", newFilters.name);
            }

            if (newFilters.role) {
                searchParams.set("Role", newFilters.role);
            }

            navigate(`${location.pathname}?${searchParams.toString()}`);
        },
        [navigate]
    );

    const handleClearFilters = React.useCallback(() => {
        setFilterValues({ name: "", email: "", role: "" });
        updateSearchParams({}, sortDir);
    }, [updateSearchParams, sortDir]);

    const handleStatusChange = React.useCallback(
        (_, value) => {
            // console.log(_.target.value, "space",  value);
            setFilterValues({ ...filterValues, status: value });
            updateSearchParams({ ...filterValues, status: value }, sortDir);
        },
        [updateSearchParams, filterValues, sortDir]
    );

    const handleFilterChange = React.useCallback(
        (name, value) => {
            setFilterValues({ ...filterValues, [name]: value });
            updateSearchParams({ ...filterValues, [name]: value }, sortDir);
        },
        [updateSearchParams, filterValues, sortDir]
    );

    const handleSortChange = React.useCallback(
        (event) => {
            const newSortDir = event.target.value;
            setSortDir(newSortDir);
            updateSearchParams(filterValues, newSortDir);
        },
        [updateSearchParams, filterValues]
    );

    useEffect(() => {
        setHasFilters(filterValues.role || filterValues.email || filterValues.name);

        return () => {};
    }, [filterValues.role, filterValues.email, filterValues.name]);

    return (
        <div>
            {statuses?.values?.length > 0 && (
                <Tabs
                    onChange={handleStatusChange}
                    sx={{ px: 1 }}
                    value={filterValues.status || statuses.values[0]}
                    variant="scrollable"
                >
                    {statuses?.values?.map((tab) => (
                        <Tab
                            iconPosition="end"
                            key={tab}
                            label={tab}
                            sx={{ minHeight: "auto" }}
                            tabIndex={0}
                            value={tab}
                        />
                    ))}
                </Tabs>
            )}
            <Divider />
            <Stack direction="row" spacing={2} sx={{ alignItems: "center", flexWrap: "wrap", px: 3, py: 2 }}>
                <Stack direction="row" spacing={2} sx={{ alignItems: "center", flex: "1 1 auto", flexWrap: "wrap" }}>
                    {filters
                        ?.filter((f) => f.fieldType !== "tab")
                        ?.map((f) => (
                            <FilterButton
                                key={f.field}
                                displayValue={filterValues[f.field]}
                                label={f.label}
                                onFilterApply={(value) => {
                                    handleFilterChange(f.field, value);
                                }}
                                onFilterDelete={() => {
                                    handleFilterChange(filterValues.name, "");
                                }}
                                popover={
                                    f.fieldType === "text" ? (
                                        <TextFilterPopover item={f} />
                                    ) : (
                                        <DropdownFilterPopover item={f} />
                                    )
                                }
                                value={filterValues[f.field] || ""}
                            />
                        ))}

                    {hasFilters ? <Button onClick={handleClearFilters}>Clear filters</Button> : null}
                </Stack>
                {selection.selectedAny ? (
                    <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                        <Typography color="text.secondary" variant="body2">
                            {selection.selected.size} selected
                        </Typography>
                        <Button color="error" variant="contained">
                            Delete
                        </Button>
                    </Stack>
                ) : null}
                {sorts.length > 0 && (
                    <Select
                        name="sort"
                        onChange={handleSortChange}
                        sx={{ maxWidth: "100%", width: "150px" }}
                        value={sortDir}
                        size="small"
                    >
                        {sorts?.map((s) => (
                            <Option key={s.field} value={s.field}>
                                {s.label}
                            </Option>
                        ))}
                    </Select>
                )}
            </Stack>
        </div>
    );
}

function TextFilterPopover({ item }) {
    const { anchorEl, onApply, onClose, open, value: initialValue } = useFilterContext();
    const [value, setValue] = React.useState("");

    React.useEffect(() => {
        setValue(initialValue ?? "");
    }, [initialValue]);

    return (
        <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title={item.labelDescription}>
            <FormControl>
                <OutlinedInput
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    onKeyUp={(event) => {
                        if (event.key === "Enter") {
                            onApply(value);
                        }
                    }}
                    value={value}
                />
            </FormControl>
            <Button
                onClick={() => {
                    onApply(value);
                }}
                variant="contained"
            >
                Apply
            </Button>
        </FilterPopover>
    );
}

function DropdownFilterPopover({ item }) {
    const { anchorEl, onApply, onClose, open, value: initialValue } = useFilterContext();
    const [value, setValue] = React.useState("");

    React.useEffect(() => {
        setValue(initialValue ?? "");
    }, [initialValue]);

    return (
        <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title={item.labelDescription}>
            <FormControl>
                <InputLabel id="demo-simple-select-label">{item.label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={item.label}
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    onKeyUp={(event) => {
                        if (event.key === "Enter") {
                            onApply(value);
                        }
                    }}
                >
                    {item?.values.map((v) => (
                        <MenuItem key={v} value={v}>
                            {v}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                onClick={() => {
                    onApply(value);
                }}
                variant="contained"
            >
                Apply
            </Button>
        </FilterPopover>
    );
}
