import React from "react";
import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { dayjs } from "../../Helpers/dayjs";
import { Ranking as RankingIcon } from "@phosphor-icons/react/dist/ssr/Ranking";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import { DataTable } from "../../components/DataTable/DataTable";
import { DataTablePagination } from "../../components/DataTable/DataTablePagination";

const progressColumns = [
    {
        formatter: (row) => (
            <Link
                color="inherit"
                // component={RouterLink}
                // href={`/admin/organizations/${orgId}/users/1`}
                sx={{ whiteSpace: "nowrap" }}
                variant="subtitle2"
            >
                {row.assessment}
            </Link>
        ),
        name: "Assessment",
        width: "200px",
    },
    {
        formatter: (row) => (
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                <LinearProgress sx={{ flex: "1 1 auto" }} value={row.progress} variant="determinate" />
                <Typography color="text.secondary" variant="body2">
                    {new Intl.NumberFormat("en-US", { style: "percent", maximumFractionDigits: 2 }).format(
                        row.progress / 100
                    )}
                </Typography>
            </Stack>
        ),
        name: "Quota",
        align: "center",
        width: "150px",
    },

    { field: "sessions", name: "Sessions", width: "150px", align: "center" },
    {
        formatter(row) {
            return dayjs(row.dateStarted).format("MMM D, YYYY h:mm A");
        },
        name: "Date Started",
        align: "center",
        width: "150px",
    },
    {
        formatter: (row) => {
            return (
                <IconButton
                // component={RouterLink} to={editLink.href}
                >
                    <RankingIcon />
                </IconButton>
            );
        },
        name: "Results",
        // hideName: true,
        width: "100px",
        align: "center",
    },
];

const progressAssessments = [
    {
        assessment: "Service Manager",
        progress: 52,
        sessions: 1,
        dateStarted: dayjs().subtract(3, "hour").toDate(),
    },
    {
        assessment: "Collision Damage Repair Technician",
        progress: 97,
        sessions: 2,
        dateStarted: dayjs().subtract(1, "hour").subtract(1, "day").toDate(),
    },
    {
        assessment: "Automotive Service Technician",
        progress: 30,
        sessions: 3,
        dateStarted: dayjs().subtract(7, "hour").subtract(1, "day").toDate(),
    },
    {
        assessment: "Brake & Steering and Suspension",
        progress: 81,
        sessions: 1,
        dateStarted: dayjs().subtract(2, "hour").subtract(2, "day").toDate(),
    },
];

const completedAssessments = [
    {
        assessment: "Service Manager",
        sessions: 1,
        dateStarted: dayjs().subtract(3, "hour").toDate(),
    },
    {
        assessment: "Collision Damage Repair Technician",
        sessions: 2,
        dateStarted: dayjs().subtract(1, "hour").subtract(1, "day").toDate(),
    },
    {
        assessment: "Automotive Service Technician",
        sessions: 3,
        dateStarted: dayjs().subtract(7, "hour").subtract(1, "day").toDate(),
    },
    {
        assessment: "Brake & Steering and Suspension",
        sessions: 1,
        dateStarted: dayjs().subtract(2, "hour").subtract(2, "day").toDate(),
    },
];

const completedColumns = [
    {
        formatter: (row) => (
            <Link
                color="inherit"
                // component={RouterLink}
                // href={`/admin/organizations/${orgId}/users/1`}
                sx={{ whiteSpace: "nowrap" }}
                variant="subtitle2"
            >
                {row.assessment}
            </Link>
        ),
        name: "Assessment",
        width: "200px",
    },
    { field: "sessions", name: "Sessions", width: "150px", align: "center" },
    {
        formatter(row) {
            return dayjs(row.dateStarted).format("MMM D, YYYY h:mm A");
        },
        name: "Date Completed",
        align: "center",
        width: "150px",
    },
    {
        formatter: (row) => {
            return (
                <IconButton
                // component={RouterLink} to={editLink.href}
                >
                    <EyeIcon />
                </IconButton>
            );
        },
        name: "View",
        width: "100px",
        align: "center",
    },
];

export default function Assessments() {
    const theme = useTheme();
    const smmatches = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div>
            <Typography component="h2" variant="overline">
            Assessments
            </Typography>
            <Typography component="h1" variant="h5" gutterBottom>
                Assessments Dashboard
            </Typography>

            <Paper sx={{ my: 2 }}>
                <Grid container justifyContent="center">
                    <Grid
                        item
                        md={6}
                        xs={12}
                        sx={{
                            py: 6,
                            px: 2,
                        }}
                    >
                        <Typography component="h1" variant="h4" gutterBottom>
                            Start New Assessment
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            To start an assessment please click on the button below. You will be presented with a list
                            of available assessments to choose from.
                        </Typography>
                        <div
                            style={{
                                marginTop: 30,
                                marginBottom: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                component={RouterLink}
                                to={"/portal/assessments/new"}
                            >
                                Start New Assessment
                            </Button>
                        </div>
                    </Grid>
                    {!smmatches && (
                        <Grid
                            item
                            md={6}
                            xs={12}
                            align="center"
                            style={{
                                padding: theme.spacing(1),
                            }}
                        >
                            <img src="/images/mock/assessment.png" alt="assessments" style={{ width: "280px" }} />
                        </Grid>
                    )}
                </Grid>
            </Paper>
            <Stack spacing={1} sx={{ mt: 2 }}>
                <Stack direction={{ xs: "column", sm: "column" }} sx={{ alignItems: "flex-start" }}>
                    <Typography variant="h5">Assessments in Progress</Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        The following is a list of assessments which you have started but not yet completed. To continue
                        an assessment simply click on the assessment's title.
                    </Typography>
                </Stack>
                <Card>
                    <Box sx={{ overflowX: "auto" }}>
                        <DataTable columns={progressColumns} rows={progressAssessments} dense={true} />
                        {!completedAssessments.length ? (
                            <Box sx={{ p: 3 }}>
                                <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                                    No assessments in progress
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                    <Divider />
                    <DataTablePagination count={progressAssessments.length + 6} />
                </Card>
            </Stack>

            <Stack spacing={1} sx={{ mt: 2 }}>
                <Stack direction={{ xs: "column", sm: "column" }} sx={{ alignItems: "flex-start" }}>
                    <Typography variant="h5">Completed Assessments</Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        The following list contains all of your completed assessments. You can view the results of any
                        assessment by clicking on the assessment's title.
                    </Typography>
                </Stack>
                <Card>
                    <Box sx={{ overflowX: "auto" }}>
                        <DataTable columns={completedColumns} rows={completedAssessments} dense={true} />
                        {!completedAssessments.length ? (
                            <Box sx={{ p: 3 }}>
                                <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                                    No customers found
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                    <Divider />
                    <DataTablePagination count={completedAssessments.length + 6} />
                </Card>
            </Stack>
        </div>
    );
}

function ProgressBar(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}
