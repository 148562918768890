import { createTheme } from "@mui/material/styles";
import typography from "./typography";

const white = "#FFFFFF";

const themes = {
    cars: createTheme({
        palette: {
            primary: {
                contrastText: white,
                main: "#1976d2",
            },
            secondary: {
                contrastText: "#1976d2",
                main: white,
            },
            icon: "#1976d2",
            text: {
                primary: "rgba(0, 0, 0, 0.87)",
                secondary: "#727982",
            },
        },
        typography: {
            // fontFamily: "Roboto, sans-serif",
            typography,
        },
    }),
    napa: createTheme({
        palette: {
            primary: {
                main: "#28A745",
            },
            secondary: {
                main: "#FFC107",
            },
        },
        typography: {
            fontFamily: "Arial, sans-serif",
        },
    }),
    // Add more themes for other organizations
};

export default themes;
