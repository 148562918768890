import React, { useEffect, useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Chip, Divider, IconButton, Link, Paper, Typography } from "@mui/material";
import { DataTable } from "../../components/DataTable/DataTable";
import { Play as PlayIcon } from "@phosphor-icons/react/dist/ssr/Play";
import { DataTablePagination } from "../../components/DataTable/DataTablePagination";

const columns = [
    {
        formatter: (row) => (
            <Link
                color="inherit"
                component={RouterLink}
                to={`/portal/assessments/${row.id}`}
                sx={{ whiteSpace: "nowrap" }}
                variant="subtitle2"
            >
                {row.assessment}
            </Link>
        ),
        name: "Assessment",
        width: "300px",
    },
    {
        formatter: (row) => {
            const mapping = {
                completed: { label: "Completed", color: "success" },
                started: { label: "In Progress", color: "warning" },
                new: { label: "Not Started", color: "error" },
            };
            const { label, color } = mapping[row.status] ?? { label: "Unknown", color: "secondary" };

            return <Chip color={color} label={label} size="small" variant="soft" />;
        },
        name: "Status",
        width: "200px",
        align: "center",
    },
    {
        formatter: (row) => (
            <Typography sx={{ whiteSpace: "nowrap" }} variant="inherit">
                {row.sessions}
            </Typography>
        ),
        name: "Sessions",
        width: "200px",
        align: "center",
    },
    {
        formatter: (row) => {
            return (
                <IconButton component={RouterLink} to={`/portal/assessments/${row.id}`}>
                    <PlayIcon />
                </IconButton>
            );
        },
        name: "Action",
        width: "100px",
        align: "center",
    },
];

const rows = [
    {
        id: "a-1",
        assessment: "Advanced Hybrid Technologies",
        status: "new",
        sessions: 0,
    },
    { id: "a-2", assessment: "Forklift Service Technician", status: "new", sessions: 0 },
    {
        id: "a-3",
        assessment: "Alignment and Brake Technician",
        status: "new",
        sessions: 0,
    },
    {
        id: "a-4",
        assessment: "Brake & Steering and Suspension",
        status: "new",
        sessions: 0,
    },
];

export default function NewAssessments() {
    const isMounted = useRef(false);
    const [occupations, setOccupations] = useState([]);

    useEffect(() => {
        isMounted.current = true;
        const token = JSON.parse(localStorage.getItem("user")).token;

        fetch("", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setOccupations(data);
            });

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <div>
            <Typography component="h2" variant="overline">
                CARSability
            </Typography>
            <Typography variant="h5">Select New Assessment</Typography>
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                Click on an assessment below to select your assessment. Please note that while you can retake
                assessments, you may only have one assessment per occupation at any time.
            </Typography>
            <Paper>
                <DataTable columns={columns} rows={rows} dense={true} />
                <Divider />
                <DataTablePagination count={rows.length + 36} />
            </Paper>
        </div>
    );
}
