export const drawerToggle = (drawerOpen) => ({
    type: "TOGGLE_DRAWER",
    drawerOpen,
});
export const drawerOpen = () => ({
    type: "DRAWER_OPEN",
});
export const drawerClose = () => ({
    type: "DRAWER_CLOSE",
});
export const updateMenuType = (menuType) => ({
    type: "UPDATE_MENU_TYPE",
    menuType,
});
