import {
    Avatar,
    Box,
    Button,
    Card,
    CardHeader,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import settings from "../../settings/api";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";
import { useNavigate } from "react-router-dom";
import { Invoice as InvoiceIcon } from "@phosphor-icons/react/dist/ssr/Invoice";

export default function QuickQuote() {
    const subdomain = getSubdomain();
    const domain = getDomain();
    const navigate = useNavigate();
    const [selectedValues, setSelectedValues] = useState({
        year: "",
        make: "",
        model: "",
        trim: "",
        option: "",
    });
    const [years, setYears] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [trims, setTrims] = useState([]);
    const [options, setOptions] = useState([]);
    
    const fetchData = (url, method, setState) => {
        fetch(url, {
            method,
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .then((data) => setState(data))
            .catch((err) => console.error(err));
    };
    
    useEffect(() => {
        fetchData(settings.api().vehicleYear, "GET", setYears);
    }, []);
    
    const handleSelect = (field, value, linkRel, setState) => {
        const resetFields = {
            year: { make: "", model: "", trim: "", option: "" },
            make: { model: "", trim: "", option: "" },
            model: { trim: "", option: "" },
            trim: { option: "" },
        };
    
        setSelectedValues((prevValues) => ({
            ...prevValues,
            [field]: value,
            ...resetFields[field],
        }));
        
        const link = value?.links.find((link) => link.rel === linkRel);
        if (link) {
            fetchData(settings.api().host + link.href, link.method, setState);
        }
    };
    
    const handleSelectYear = (e) => handleSelect("year", e.target.value, "vehicleMakes", setMakes);
    const handleSelectMake = (e) => handleSelect("make", e.target.value, "vehicleModels", setModels);
    const handleSelectModel = (e) => handleSelect("model", e.target.value, "vehicleTrims", setTrims);
    const handleSelectTrim = (e) => handleSelect("trim", e.target.value, "vehicleOptions", setOptions);
    
    return (
        <Card sx={{ height: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar>
                        <InvoiceIcon fontSize="large" />
                    </Avatar>
                }
                title={<Typography variant="h6">Quick Quote</Typography>}
            />
            <Box sx={{ px: 2, pb: 2 }}>
                <Typography gutterBottom sx={{ fontSize: "18px" }}>
                    Search Vehicle
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                        // flexDirection: { xs: "column", md: "row" }
                    }}
                >
                    <FormControl sx={{ flexGrow: 1, minWidth: "150px" }} size="small">
                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValues.year}
                            label="Year"
                            onChange={(e) => handleSelectYear(e)}
                        >
                            {years.length > 0 &&
                                years.map((y) => (
                                    <MenuItem key={y.year} value={y}>
                                        {y.year}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{ flexGrow: 1, minWidth: "150px" }}
                        size="small"
                        disabled={selectedValues.year === ""}
                    >
                        <InputLabel id="demo-simple-select-label">Make</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValues.make}
                            label="Make"
                            onChange={(e) => handleSelectMake(e)}
                        >
                            {makes.length > 0 &&
                                makes.map((m) => (
                                    <MenuItem key={m.makeId} value={m}>
                                        {m.makeName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{ flexGrow: 1, minWidth: "150px" }}
                        size="small"
                        disabled={selectedValues.make === ""}
                    >
                        <InputLabel id="demo-simple-select-label">Model</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValues.model}
                            label="Model"
                            onChange={e => handleSelectModel(e)}
                        >
                            {models.length > 0 &&
                                models.map((m) => (
                                    <MenuItem key={m.modelId} value={m}>
                                        {m.modelName}
                                    </MenuItem>
                                ))}                           
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{ flexGrow: 1, minWidth: "150px" }}
                        size="small"
                        disabled={selectedValues.model === ""}
                    >
                        <InputLabel id="demo-simple-select-label">Trim</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValues.trim}
                            label="Trim"
                            onChange={e => handleSelectTrim(e)}
                        >
                           {trims.length > 0 &&
                                trims.map((t) => (
                                    <MenuItem key={t.trimId} value={t}>
                                        {t.trimName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{ flexGrow: 1, minWidth: "150px" }}
                        size="small"
                        disabled={selectedValues.trim === ""}
                    >
                        <InputLabel id="demo-simple-select-label">Option</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={age}
                            label="Option"
                            // onChange={handleChange}
                        >
                             {options.length > 0 &&
                                options.map((t) => (
                                    <MenuItem key={t.trimId} value={t}>
                                        {t.trimName}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Box>
                        <Button variant="outlined" sx={{ mr: 2 }}>
                            Clear
                        </Button>
                        <Button variant="contained" onClick={() => navigate("/portal/tires/quote")}>
                            Search
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
}
