import React from "react";
// import { connect } from "react-redux";
import CopyRight from "../../components/CopyRight";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import MiniDrawer from "../Drawer";

const Portal = () => {
    useEffect(() => {
        console.log("component_name rendered");
    });
    return (
        <Box
            sx={{
                position: "relative",
                minHeight: `calc(100vh - 140px)` /* will cover the 100% of viewport */,
                overflow: "hidden",
                display: "block",
                paddingBottom: "60px" /* height of your footer */,
            }}
        >
            {/* this is for nested route, the nested route component with be placed here instead */}
            <Outlet />
            <Box
                style={{
                    position: "absolute",
                    bottom: 10,
                    width: "100%",
                    margin: "auto",
                    height: "40px",
                }}
                component="footer"
            >
                <CopyRight />
            </Box>
        </Box>
    );
};

export const PortalLayout = (props) => {
    return (
        <Box>
            <MiniDrawer>
                <Outlet /> 
            </MiniDrawer>
        </Box>
    );
};

export default Portal;
