import React from "react";

// import Link from "@material-ui/core/Link";
// import Typography from "@material-ui/core/Typography";

// import { makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
// import styled from "@emotion/styled";

// const useStyles = styled((theme) => ({
//     copyright: {
//         // margin: theme.spacing(4, 0, 6),
//     },
// }));

export default function CopyRight() {
    // const classes = useStyles();

    return (
        <div 
        // className={classes.copyright}
        >
            <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                {new Date().getFullYear()}{" "}
                <Link target="_blank" color="inherit" href="https://www.saphera.com/">
                    Saphera Software
                </Link>
                <br />
                {"Created By "}
                <Link target="_blank" color="inherit" href="https://www.saphera.com/">
                    Saphera Software
                </Link>
            </Typography>
        </div>
    );
}
