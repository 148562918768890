import React from "react";
import {
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

export default function QuoteSummary({ cartItems, onRemoveItem, onRequestQuote }) {
  const total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

    return (
        <>
            <Box sx={{ p: 2,  border: "1px solid #ddd", borderRadius: 1 }}>
                {/* Title */}
                <Typography variant="h6" gutterBottom>
                   ORDER SUMMARY
                </Typography>

                {/* List of Cart Items */}
                <List>
                    {cartItems.map((item, index) => (
                        <React.Fragment key={item.id}>
                            <ListItem>
                                <ListItemText
                                    primary={item.name}
                                    secondary={`Quantity: ${item.quantity} | Price: $${item.price.toFixed(2)}`}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => onRemoveItem(item.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {index < cartItems.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>

                {/* Total Price */}
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                    <Typography variant="subtitle1">Total:</Typography>
                    <Typography variant="subtitle1">${total.toFixed(2)}</Typography>
                </Box>

                {/* Request Quote Button */}
                <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={onRequestQuote}>
                    SUBMIT ORDER
                </Button>
            </Box>
        </>
    );
}
