import * as React from 'react';

function noop() {
  return undefined;
}

export const CustomersSelectionContext = React.createContext({
  deselectAll: noop,
  deselectOne: noop,
  selectAll: noop,
  selectOne: noop,
  selected: new Set(),
  selectedAny: false,
  selectedAll: false,
});

export function CustomersSelectionProvider({ children, customers = [] }) {
  const customerIds = React.useMemo(() => customers.map((customer) => customer.id), [customers]);
  const selection = useSelection(customerIds);

  return <CustomersSelectionContext.Provider value={{ ...selection }}>{children}</CustomersSelectionContext.Provider>;
}

export function useCustomersSelection() {
  return React.useContext(CustomersSelectionContext);
}


function useSelection(keys = []) {
    const [selected, setSelected] = React.useState(new Set());
  
    React.useEffect(() => {
      setSelected(new Set());
    }, [keys]);
  
    const handleDeselectAll = React.useCallback(() => {
      setSelected(new Set());
    }, []);
  
    const handleDeselectOne = React.useCallback((key) => {
      setSelected((prev) => {
        const copy = new Set(prev);
        copy.delete(key);
        return copy;
      });
    }, []);
  
    const handleSelectAll = React.useCallback(() => {
      setSelected(new Set(keys));
    }, [keys]);
  
    const handleSelectOne = React.useCallback((key) => {
      setSelected((prev) => {
        const copy = new Set(prev);
        copy.add(key);
        return copy;
      });
    }, []);
  
    const selectedAny = selected.size > 0;
    const selectedAll = selected.size === keys.length;
  
    return {
      deselectAll: handleDeselectAll,
      deselectOne: handleDeselectOne,
      selectAll: handleSelectAll,
      selectOne: handleSelectOne,
      selected,
      selectedAny,
      selectedAll,
    };
  }