import { Button, Card, CardActions, CardContent, TextField } from "@mui/material";
import React from "react";

export default function Reports() {
    return (
        <div>
            <h1>Reports</h1>
            <Card>
                <CardContent sx={{pb: 0}}>
                    <TextField
                        fullWidth
                        id="outlined-multiline-static"
                        label="Please enter an AI powered search query"
                        helperText="32 Queries remaining this month"
                        multiline
                        rows={4}
                        autoFocus
                        // defaultValue="Default Value"
                        placeholder="Sample: Prepare a report based on weather, CPRO (customer paid repair order) and new vehicle sales (historical and future forecast) to prepare a forecast for the next 3 months by dealer. Include model years, model, trim, tire SKUs and descriptions."
                    />
                </CardContent>
                <CardActions>
                    <Button variant="contained" sx={{ ml: 1 }}>
                        GET REPORT
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}
