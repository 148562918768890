// import React from "react";
// import { Grid, Typography } from "@mui/material";
// import styled from "@emotion/styled";

// // import { makeStyles, Typography, Grid } from '@material-ui/core';

// // const useStyles = makeStyles((theme) => ({
// //     containerBlock: {
// //         alignItems: "center",
// //         color: "#4d4d4d",
// //         margin: "0 auto",
// //         left: "0",
// //         right: "0",
// //         padding: "20px",
// //         position: "absolute",
// //         textAlign: "center",
// //         transform: "translateY(-50%)",
// //         top: "50%",
// //     },
// //     subtitleText: {
// //         color: "#4d4d4d",
// //     },
// // }));

// const useStyles = styled((theme) => ({
//     containerBlock: {
//         alignItems: "center",
//         color: "#4d4d4d",
//         margin: "0 auto",
//         left: "0",
//         right: "0",
//         padding: "20px",
//         position: "absolute",
//         textAlign: "center",
//         transform: "translateY(-50%)",
//         top: "50%",
//     },
//     subtitleText: {
//         color: "#4d4d4d",
//     },
// }));

// export default function Error404() {
//     const classes = useStyles();

//     return (
//         <div className={classes.containerBlock}>
//             <Grid>
//                 {/* <Grid item md>
//                     <img src="/shrug.png" alt="Error 404" />
//                 </Grid> */}
//                 <Grid item md>
//                     <Typography component="h1" variant="h4">
//                         ERROR 404 ¯\_(ツ)_/¯
//                     </Typography>
//                     <Typography variant="h5" className={classes.subtitleText}>
//                         Did you <a href="/login">Sign in</a>? Or return to <a href="/">Home</a>.
//                     </Typography>
//                 </Grid>
//             </Grid>
//         </div>
//     );
// }

import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const metadata = { title: `Not found` };

export default function NotFound() {
    return (
        <Box
            component="main"
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "100%",
                py: "64px",
            }}
        >
            <Box maxWidth="lg">
                <Stack spacing={6}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                            alt="Not found"
                            component="img"
                            src="/images/mock/404.png"
                            sx={{ height: "auto", maxWidth: "100%", width: "500px" }}
                        />
                    </Box>
                    <Stack spacing={1} sx={{ textAlign: "center" }}>
                        <Typography variant="h4">404: The page you are looking for isn&apos;t here</Typography>
                        <Typography color="textSecondary">
                            You either tried some shady route or you came here by mistake. Whichever it is, ensure the
                            URL is correct or try using the navigation.
                        </Typography>
                    </Stack>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button component={RouterLink} to={"/"} variant="contained">
                            Back to home
                        </Button>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}
