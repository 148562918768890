const settings = {
    dev_env: {
        env: "PROD", // PROD or DEV
        api_host_dev: "http://localhost:8080",
        // api_host_prod: "http://cars-api.sapherasaas.com",
        // api_host_prod: "http://napa-api.sapherasaas.com",
        api_host_prod: "https://services.sapherasaas.com",
        api_path: "/api/v1/",
        api_request_header_pagination: "x-pagination",
    },
    getSubdomain() {
        const hostname = window.location.hostname;
        const subdomain = hostname.split(".")[0];
        return subdomain;
    },
    api(value = undefined) {
        // Set environment variables
        var host = "";
        if (this.dev_env.env === "DEV") {
            host = this.dev_env.api_host_dev;
        } else {
            host = this.dev_env.api_host_prod;
            // const subdomain = this.getSubdomain();
            // host = `http://${subdomain}-api.sapherasaas.com`;
        }

        const route = host + this.dev_env.api_path;

        // return route;
        return {
            host: host,
            domains: route + "public/domains",
            portalInfo: route + "public/portal-info",
            authenticate: route + "authenticate",
            checkToken: route + "check-token",
            // standard
            // sites: route + "sites",
            mySite: route + "me/site",
            users: route + "me/site/users",
            versions: route + "versions",
            vehicleYear: route + "vehicles/years",


            // admin
            groups: route + "admin/groups",
            organizations: route + "admin/organizations",
            organizationUsers: route + `admin/organizations/${value}/users`,
            organizationSites: route + `admin/organizations/${value}/sites`,
            organizationGroupTypes: route + `admin/organizations/${value}/group-types`,

            // settings
            standardMenu: route + "system/menus/standard",
            structure: route + "system/structure",
            content: route + "system/translations/content",
            timezones: route + "settings/timezones",
        };
    },
};
export default settings;
