import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Link, List, Typography } from "@mui/material";
import MenuItem from "./MenuItem";
import adminMenuItems from "./AdminUser";
import standardMenuItems from "./StandardUser";
import portalAdminMenuItems from "./PortalAdmin";
// import { useLocation } from "react-router-dom";
import settings from "../../settings/api";
import AdminMenuItems from "./AdminUser";
import PortalMenuItems from "./PortalAdmin";
import StandardMenuItems from "./StandardUser";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";
import { Link as RouterLink } from "react-router-dom";
import adminSitesMenuItems from "./AdminSites";
import orgAdminMenuItems from "./OrgAdmin";
import multiOrgAdminMenuItems from "./MultiOrgAdmin";

const MenuByRole = (props) => {
    // console.log(props)
    // const location = useLocation();
    const [menuList, setMenuList] = useState([]);
    const [roleMenu, setRoleMenu] = useState(null);

    const [menuType, setMenuType] = useState(props.userType);
    const [version, setVersion] = useState("");
    const [structureMenu, setStructureMenu] = useState({});

    useEffect(() => {
        setVersion(props.structureMenu.version);
        setStructureMenu(props.structureMenu);

        return () => {};
    }, [props.structureMenu]);

    useEffect(() => {
        setMenu();
    }, [props.userType, structureMenu]);

    const setMenu = () => {
        switch (props.userType) {
            case "sitesAdmin":
                setMenuList(structureMenu?.siteAdminView?.menuItems);
                break;
            case "orgAdmin":
                setMenuList(structureMenu?.portalAdminView?.menuItems);
                break;
            case "multiOrgAdmin":
                setMenuList(structureMenu?.globalAdminView?.menuItems);
                break;
            case "user":
                setMenuList(structureMenu?.userView?.menuItems);
                break;
            default:
                // Handle default case if needed
                setMenuList([]);
                break;
        }
    };
    // console.log(menuList);

    // const GetStandardMenu = () => {
    //     const token = JSON.parse(localStorage.getItem("user")).token;

    //     const domain = getDomain();
    //     const subdomain = getSubdomain();
    //     // console.log(settings.api().standardMenu, "X-Tenant", subdomain,
    //     //         "Authorization", `Bearer ${token}`,);
    //     return fetch(settings.api().standardMenu, {
    //         headers: {
    //             "X-Domain": domain,
    //             "X-Tenant": subdomain,
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    //         .then((res) => {
    //             // console.log(res);
    //             return res.json();
    //         })
    //         .then((data) => {
    //             // console.log(data);
    //             setMenuList(data.items);

    //             setVersion(data.version);
    //             // setRoleMenu(data.items);
    //         })
    //         .catch((err) => console.error(err));
    // };

    // const getMenusStructure = () => {
    //     const token = JSON.parse(localStorage.getItem("user")).token;

    //     const domain = getDomain();
    //     const subdomain = getSubdomain();
    //     // console.log(settings.api().standardMenu, "X-Tenant", subdomain,
    //     //         "Authorization", `Bearer ${token}`,);
    //     return fetch(settings.api().structure, {
    //         headers: {
    //             "X-Domain": domain,
    //             "X-Tenant": subdomain,
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    //         .then((res) => {
    //             // console.log(res);
    //             return res.json();
    //         })
    //         .then((data) => {
    //             console.log(data);
    //             setSturctureMenu(data);
    //             setVersion(data.version);
    //         })
    //         .catch((err) => console.error(err));
    // };

    return (
        <div style={{ backgroundColor: "#182434" }}>
            {menuList?.map((item, i) => {
                return (
                    <div key={i}>
                        <MenuItem
                            openDrawer={props.open}
                            isMenuItem={item.menuItem}
                            label={item.label}
                            children={item.children ? item.children : undefined}
                            path={item.path}
                            icon={item.icon}
                            subMenu={item.subMenu ? item.subMenu : []}
                            subtitle={item.subtitle}
                            itemType={item.itemType}
                        />
                    </div>
                );
            })}
            {props.open && (
                <Box display="flex" justifyContent="center">
                    <Link
                        component={RouterLink}
                        to="/portal/versions"
                        sx={{ mt: 4, textDecoration: "none", "&:hover": { textDecoration: "none" } }}
                        color="#949ca7"
                    >
                        <Typography variant="caption">Version: {version}</Typography>
                    </Link>
                </Box>
            )}
        </div>
    );
};

// export default Menu;
const mapStateToProps = (state) => {
    return {
        open: state.componentsReducer.drawerOpen,
        menuType: state.componentsReducer.menuType,
    };
};

export default connect(mapStateToProps)(MenuByRole);
