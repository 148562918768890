const formatPhone = (phone) => {
    
    const cleaned = ('' + phone).replace(/\D/g, '');

    // Format the number as (xxx) xxx-xxxx
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phone;
};

export default formatPhone;
