import React, { useState } from "react";
import { Box, Grid, Pagination } from "@mui/material";
import ProductCard from "./ProductCard";
import TireResultFilter from "./TireResultFilter";
import QuoteSummary from "./ProductQuoteSummary";

export default function TireResults() {
    const [cartItems, setCartItems] = useState([
        { id: 1, name: "Tire A", price: 100, quantity: 2 },
        { id: 2, name: "Tire B", price: 120, quantity: 1 },
        { id: 3, name: "Tire C", price: 90, quantity: 3 },
    ]);

    const [page, setPage] = useState(1);

    const productsPerPage = 4; // Number of products per page
    const totalProducts = 20; // Total number of products

    const handleRemoveItem = (id) => {
        setCartItems(cartItems.filter((item) => item.id !== id));
    };

    const handleRequestQuote = () => {
        alert("Quote requested for items in the cart");
    };

    const totalPages = Math.ceil(totalProducts / productsPerPage);

    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <Grid container spacing={1} sx={{mt: 2}}>
            <Grid item xs={3}>
                <TireResultFilter />
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    {/* Add another grid container here for internal spacing */}
                    {[1, 2, 3, 4].map((product) => (
                        <Grid item key={product} xs={12}>
                            <ProductCard />
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                            <Pagination
                                count={10}
                                page={page} // Current page
                                onChange={handleChange}
                                color="primary"
                                sx={{ mt: 2 }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <QuoteSummary
                    cartItems={cartItems}
                    onRemoveItem={handleRemoveItem}
                    onRequestQuote={handleRequestQuote}
                />
            </Grid>
        </Grid>
    );
}
