import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import settings from "../../../settings/api";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { getDomain, getSubdomain } from "../../../Helpers/getSubdomain";
import { DataTable } from "../../../components/DataTable/DataTable";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";

const organizationId = JSON.parse(localStorage.getItem("AdminSelectedOrg"))?.id;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }} component={Card}>{children}</Box>}
        </div>
    );
}

const columns = [
    {
        field: "code",
        name: "Code",
        width: "150px",
    },
    { field: "name", name: "Site Name", width: "150px" },
    {
        formatter(row) {
            return row.physicalLocation ? "Present" : "None";
        },
        name: "Physical Location",
        width: "150px",
    },
    {
        formatter: (row) => {
            return row.organization?.shortName;
        },
        name: "Organization",
        width: "150px",
    },
    {
        formatter: (row) => {
            return row.active ? "Active" : "-";
        },
        name: "Active",
        width: "150px",
    },
    {
        formatter: (row) => {
            return (
                <IconButton component={RouterLink} sx={{ color: "text.primary"}} to={`/admin/organizations/${organizationId}/sites/${row.id}`}>
                    <PencilSimpleIcon />
                </IconButton>
            );
        },
        field: "edit",
        name: "Edit",
        width: "150px",
        align: "center"
    },
];

const Sites = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const [sites, setSites] = useState([]);
    const organizationId = JSON.parse(localStorage.getItem("AdminSelectedOrg")).id;

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        const domain = getDomain();
        const subdomain = getSubdomain();
        fetch(settings.api().organizations + `/${organizationId}/sites`, {
            method: "GET",
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                console.log(data);
                setSites(data);
            });

        return () => {};
    }, []);

    return (
        <div>
            <Typography variant="h4">Sites</Typography>
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 2, mb: 1 }}>
                <div>
                    <Button startIcon={<DownloadIcon />}>Import</Button>
                    <Button startIcon={<FileUploadIcon />}>Export</Button>
                </div>
                <div>
                    <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        onClick={() => {
                            navigate(`/admin/organizations/${organizationId}/sites/new`);
                        }}
                    >
                        New Site
                    </Button>
                </div>
            </Stack>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="All Sites" {...a11yProps(0)} />
                    {/* <Tab label="Parents ONLY" {...a11yProps(1)} /> */}
                </Tabs>
            </Box>
            <Paper sx={{ mt: 2 }}>
                <CustomTabPanel value={tabValue} index={0}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Search Site"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <DataTable columns={columns} rows={sites} />
                    {/* <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Site Name</TableCell>
                                    <TableCell>Physical Location</TableCell>
                                    <TableCell>Organization</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell align="center">Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sites.length > 0 ? (
                                    sites.map((o, i) => {
                                        // console.log(o.id);
                                        return (
                                            <TableRow
                                                key={i}
                                                // onClick={() => {
                                                //     navigate(`/admin/organizations/${organizationId}/sites/${o.id}`, {
                                                //         state: {
                                                //             siteId: o.id,
                                                //         },
                                                //     });
                                                // }}
                                                // component={Link}
                                                // to={`/admin/organizations/sites/${o.id}`}
                                            >
                                                <TableCell>
                                                    <Typography>{o?.code}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{o?.name}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{o?.physicalLocation ? "Present" : "None"}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{o?.organization?.shortName}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{o?.active ? "Active" : "-"}</Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        aria-label="edit"
                                                        component={Link}
                                                        to={`/admin/organizations/${organizationId}/sites/${o.id}`}
                                                        state={{ siteId: o.id }}
                                                        color="primary"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            There is no site.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}></CustomTabPanel>
            </Paper>
        </div>
    );
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default Sites;
