import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

export default function ShopStatsSummary() {
    return (
        <Box  sx={{mb: 2}}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{mb: 2}}>
                <Typography variant="h6">This month</Typography>
                <Button variant="outlined" size="small">
                    Full Report
                </Button>
            </Box>
            {/* <Typography variant="h6">This month</Typography> */}
            <Box
                sx={{
                    display: "grid",
                    gap: 1,
                    gridTemplateColumns: { xs: "1fr", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" },
                }}
            >
                <Stack
                    spacing={1}
                    sx={{
                        borderRight: { xs: "none", md: "1px solid var(--mui-palette-divider)" },
                        borderBottom: { xs: "1px solid var(--mui-palette-divider)", md: "none" },
                        pb: { xs: 2, md: 0 },
                    }}
                >
                    <Typography color="text.secondary">Tire Sales (units)</Typography>
                    <Typography variant="h5">{new Intl.NumberFormat("en-US").format(16500)}</Typography>
                    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                        <Typography color="text.secondary" variant="body2">
                            <Typography color="success.main" component="span" variant="subtitle2">
                                {new Intl.NumberFormat("en-US", { style: "percent", maximumFractionDigits: 2 }).format(
                                    15 / 100
                                )}
                            </Typography>{" "}
                            increase vs last month
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    spacing={1}
                    sx={{
                        borderRight: { xs: "none", lg: "1px solid var(--mui-palette-divider)" },
                        borderBottom: { xs: "1px solid var(--mui-palette-divider)", md: "none" },
                        pb: { xs: 2, md: 0 },
                    }}
                >
                    <Typography color="text.secondary">All Seasons Tires</Typography>
                    <Typography variant="h5">{new Intl.NumberFormat("en-US").format(2160)}</Typography>
                    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                        <Typography color="text.secondary" variant="body2">
                            <Typography color="success.main" component="span" variant="subtitle2">
                                {new Intl.NumberFormat("en-US", { style: "percent", maximumFractionDigits: 2 }).format(
                                    10 / 100
                                )}
                            </Typography>{" "}
                            increase vs last month
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    spacing={1}
                    sx={{
                        borderRight: { xs: "none", md: "1px solid var(--mui-palette-divider)" },
                        borderBottom: { xs: "1px solid var(--mui-palette-divider)", md: "none" },
                        pb: { xs: 2, md: 0 },
                    }}
                >
                    <Typography color="text.secondary">Customers Served</Typography>
                    <Typography variant="h5">147</Typography>
                    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                        <Typography color="text.secondary" variant="body2">
                            <Typography color="error.main" component="span" variant="subtitle2">
                                {new Intl.NumberFormat("en-US", { style: "percent", maximumFractionDigits: 2 }).format(
                                    25 / 100
                                )}
                            </Typography>{" "}
                            decrease vs last month
                        </Typography>
                    </Stack>
                </Stack>
                <Stack spacing={1}>
                    <Typography color="text.secondary">Avg. Sales per Customer</Typography>
                    <Typography variant="h5">$849.24</Typography>
                    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                        <Typography color="text.secondary" variant="body2">
                            <Typography color="error.main" component="span" variant="subtitle2">
                                {new Intl.NumberFormat("en-US", { style: "percent", maximumFractionDigits: 2 }).format(
                                    15 / 100
                                )}
                            </Typography>{" "}
                            decrease vs last month
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
}
