import React from "react";

export default function MultiOrgList() {

    return (
        <div>
            <h1>Multi-Org Admin Orgranizations List</h1>
        </div>
    );
}
