const formatdate = (date) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const d = new Date(date);
    // console.log(d, defaultDate);
    const day = d.getDate();
    const month = d.getMonth();
    let year = d.getFullYear();
    // if (year.length === 1) {
    //     year = `000${year}`;
    // }
    // console.log(year);
    // return `${day} ${months[month]} ${year}`;
    return `${months[month]} ${day}, ${year}`;
};

export default formatdate;
