import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import styled from "@emotion/styled";
import RecursiveTableRow from "./RecursiveTableRow";

// const testData = [
//     {
//         id: 1,
//         name: "parent1",
//         code: "code",
//         description: "",
//         groupType: null,
//         createdUtc: "",
//         modifiedUtc: "",
//         level: 0,
//         children: [
//             {
//                 id: 3,
//                 name: "child",
//                 code: "code",
//                 description: "",
//                 groupType: null,
//                 createdUtc: "",
//                 modifiedUtc: "",
//                 level: 1,
//                 children: [
//                     {
//                         id: 4,
//                         name: "grandchild",
//                         code: "code",
//                         description: "",
//                         groupType: null,
//                         createdUtc: "",
//                         modifiedUtc: "",
//                         level: 2,
//                         children: [],
//                     },
//                 ],
//             },
//         ],
//     },
//     {
//         id: 2,
//         name: "parent2",
//         code: "code",
//         description: "",
//         groupType: null,
//         createdUtc: "",
//         modifiedUtc: "",
//         level: 0,
//         children: [],
//     },
// ];
// const StyledTableRow = styled(TableRow)({
//     "& .MuiTableRow-root": {
//         paddingLeft: "16px",
//     },
// });

const RecursiveTable = ({ headers, data, selectedGroup, handleSelectChange, ...rest }) => {
    // const [data, setData] = useState(testData);
    // const [selectedGroup, setSelectedGroup] = useState(0);

    // const handleSelectChange = (id) => {
    //     if (id === selectedGroup) {
    //         setSelectedGroup(0);
    //     }
    //     setSelectedGroup(id);
    // };

    return (
        <div>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {headers.map((h, i) => (
                                <TableCell key={i} align={h.align}>
                                    {h.title}
                                </TableCell>
                            ))}
                            {/* <TableCell></TableCell>
                            <TableCell>Group Name</TableCell>
                            <TableCell>Level</TableCell>
                            <TableCell>Parent Group</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Modified</TableCell>
                            <TableCell align="center">Select</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 &&
                            data.map((d, i) => (
                                <RecursiveTableRow
                                    key={i}
                                    data={d}
                                    selectedGroup={selectedGroup}
                                    handleSelectChange={(id) => handleSelectChange(id)}
                                />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default RecursiveTable;
