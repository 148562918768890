import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    InputAdornment,
    Paper,
    Stack,
    Tab,
    // Table,
    // TableBody,
    // TableCell,
    // TableContainer,
    // TableHead,
    // TableRow,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import settings from "../../../settings/api";
import { Link } from "react-router-dom";
// import { DataGrid } from "@mui/x-data-grid";
import RecursiveTable from "../../../components/RecursiveTable";
import { getDomain, getSubdomain } from "../../../Helpers/getSubdomain";

const headers = [
    {
        order: 1,
        title: "",
    },
    {
        order: 2,
        title: "Group Name",
    },
    {
        order: 3,
        title: "Level",
    },
    {
        order: 4,
        title: "Parent Group",
    },
    {
        order: 5,
        title: "Type",
    },
    {
        order: 6,
        title: "Last Modified",
    },
    {
        order: 7,
        title: "Action",
        align: "center",
    },
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }} component={Card}>{children}</Box>}
        </div>
    );
}
const Groups = () => {
    const domain = getDomain();
    const subdomain = getSubdomain();
    const [tabValue, setTabValue] = useState(0);
    const [groups, setGroups] = useState([]);
    const [pageContent, setPageContent] = useState([]);
    // console.log(pageContent);
    // const [groupTableHeaders, setGroupTableHeaders] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(0);

    const handleSelectChange = (id) => {
        if (id === selectedGroup) {
            setSelectedGroup(0);
        }
        setSelectedGroup(id);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        fetch(settings.api().content + "/1000", {
            method: "GET",
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setPageContent(data);
            });

        // setGroupTableHeaders(headers);
        fetch(settings.api().organizations + `/${JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId}/groups`, {
            method: "GET",
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setGroups(data);
            });

        return () => {};
    }, []);

    return (
        <div>
            <Typography variant="h4">
                {pageContent.length > 0 && pageContent?.find((c) => c.contentId === 1)?.translation}
            </Typography>
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 2, mb: 1 }}>
                <div>
                    <Button startIcon={<DownloadIcon />}>
                        {pageContent?.find((c) => c.contentId === 2)?.translation}
                    </Button>
                    <Button startIcon={<FileUploadIcon />}>
                        {pageContent?.find((c) => c.contentId === 3)?.translation}
                    </Button>
                </div>
                <div>
                    <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        component={Link}
                        to={`/admin/organizations/${JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId}/groups/new`}
                    >
                        {pageContent?.find((c) => c.contentId === 6)?.translation}
                    </Button>
                </div>
            </Stack>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label={`${pageContent?.find((c) => c.contentId === 4)?.translation}`} {...a11yProps(0)} />
                    <Tab label={`${pageContent?.find((c) => c.contentId === 5)?.translation}`} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <Paper sx={{ mt: 2 }}>
                <CustomTabPanel value={tabValue} index={0}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Search Groups"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <RecursiveTable
                        headers={headers}
                        data={groups}
                        selectedGroup={selectedGroup}
                        handleSelectChange={handleSelectChange}
                    />
                    {/* <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Group Name</TableCell>
                                    <TableCell>Level</TableCell>
                                    <TableCell>Parent Group</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Modified</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groups.length > 0 ? (
                                    groups.map((g, i) => {
                                        // console.log(g);
                                        return (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    <Typography>{g?.code}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{g?.name}</Typography>
                                                </TableCell>
                                                <TableCell>1</TableCell>
                                                <TableCell>--</TableCell>
                                                <TableCell>
                                                    <Typography>{g?.groupType?.name}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>{g?.modifiedUtc}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            There is no group.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                </CustomTabPanel>
                {/* <CustomTabPanel value={tabValue} index={1}></CustomTabPanel> */}
            </Paper>
        </div>
    );
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default Groups;
