import React from "react";
import { SimpleOrderTable } from "./SimpleOrderTable";
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import QuickQuote from "../../components/TireQuote/QuickQuote";
import SimpleShop from "./SimpleShop";
import { dayjs } from "../../Helpers/dayjs";
import { Conversions } from "../../components/ConversionChart";
import { TopProducts } from "../../components/TopSellingProductCard";

export default function Dashboard() {
    return (
        <div>
            <Stack spacing={1}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Card>
                            <CardContent
                                sx={{
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="subtitle1">WINTER TIRE BOOKING</Typography>
                                <Typography variant="subtitle1">OPEN NOW</Typography>
                                <Typography variant="h2" fontWeight={500}>6</Typography>
                                <Typography variant="subtitle1">Days left</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3}>
                        <Card>
                            <CardContent
                                sx={{
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            ><Typography variant="subtitle1">SPRING TIRE BOOKING</Typography>
                            <Typography variant="subtitle1">COMING SOON</Typography>
                            <Typography variant="h2" fontWeight={500}>30</Typography>
                            <Typography variant="subtitle1">Days to booking start</Typography>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3}>
                        <Card>
                            <CardContent
                                sx={{
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="subtitle1">WINTER TIRE BOOKING</Typography>
                                <Typography variant="subtitle1">NOT ACTIVE</Typography>
                                <Typography variant="h2" fontWeight={500} color="text.secondary">N/A</Typography>
                                <Typography variant="subtitle1">Check back soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3}>
                        <Card>
                            <CardContent
                                sx={{
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="subtitle1">SPRING TIRE BOOKING</Typography>
                                <Typography variant="subtitle1">NOT ACTIVE</Typography>
                                <Typography variant="h2" fontWeight={500} color="text.secondary">N/A</Typography>
                                <Typography variant="subtitle1">Check back soon</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={2} alignItems="stretch">
                    <Grid item xs={12} md={3}>
                        <QuickQuote />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <SimpleOrderTable />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleShop
                            messages={[
                                {
                                    id: "MSG-001",
                                    content: "Shop Technian",
                                    author: {
                                        name: "Alcides Antonio",
                                        avatar: "/assets/avatar-10.png",
                                        status: "online",
                                    },
                                    createdAt: dayjs().subtract(2, "minute").toDate(),
                                },
                                {
                                    id: "MSG-002",
                                    content: "Sales Manager",
                                    author: { name: "Marcus Finn", avatar: "/assets/avatar-9.png", status: "offline" },
                                    createdAt: dayjs().subtract(56, "minute").toDate(),
                                },
                                {
                                    id: "MSG-003",
                                    content: "Sales Representative",
                                    author: { name: "Carson Darrin", avatar: "/assets/avatar-3.png", status: "online" },
                                    createdAt: dayjs().subtract(3, "hour").subtract(23, "minute").toDate(),
                                },
                                {
                                    id: "MSG-004",
                                    content: "Sales Representative",
                                    author: { name: "Fran Perez", avatar: "/assets/avatar-5.png", status: "online" },
                                    createdAt: dayjs().subtract(8, "hour").subtract(6, "minute").toDate(),
                                },
                                {
                                    id: "MSG-005",
                                    content: "Administator",
                                    author: { name: "Jie Yan", avatar: "/assets/avatar-8.png", status: "offline" },
                                    createdAt: dayjs().subtract(10, "hour").subtract(18, "minute").toDate(),
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item lg={7} xs={12} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                        <Box>
                            <Conversions
                                data={[
                                    { name: "Tires", value: 35690 },
                                    { name: "Wheels", value: 5486 },
                                    { name: "Packages", value: 45120 },
                                    { name: "Accessories", value: 3950 },
                                ]}
                            />
                        </Box>
                    </Grid>
                    <Grid item lg={5} xs={12}>
                        <TopProducts
                            products={[
                                {
                                    id: "PRD-001",
                                    name: "P Zero",
                                    image: "/images/mock/tire1.jpg",
                                    category: "Pirelli",
                                    sales: 13153,
                                },
                                {
                                    id: "PRD-002",
                                    name: "Eagle F1 SuperCar 3R",
                                    image: "/images/mock/tire2.jpg",
                                    category: "GOODYEAR",
                                    sales: 10300,
                                },
                                {
                                    id: "PRD-003",
                                    name: "Potenza S007",
                                    image: "/images/mock/tire3.jpg",
                                    category: "BRIDGESTONE",
                                    sales: 5300,
                                },
                                {
                                    id: "PRD-004",
                                    name: "P Zero",
                                    image: "/images/mock/tire1.jpg",
                                    category: "Pirelli",
                                    sales: 1203,
                                },
                                {
                                    id: "PRD-005",
                                    name: "Potenza S007",
                                    image: "/images/mock/tire3.jpg",
                                    category: "BRIDGESTONE",
                                    sales: 254,
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </div>
    );
}
