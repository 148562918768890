// import "~@fontsource/inter/600.css";

const font = "'-apple-system', 'Segoe UI', 'Roboto', 'Inter'";
const typography = {
    typography: {
        fontFamily: font,
        // caption: {
        //     fontFamily: "Inter",
        // },
    },
};
export default typography;
