import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import settings from "../../../settings/api";
import { Link, useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import { getSubdomain } from "../../../Helpers/getSubdomain";
// import formatdate from "../../../../Helpers/DateFormatter";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
const GroupTypes = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const [groupTypes, setGroupTypes] = useState([]);
    const organizationId = JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId;
    // const [groupTableHeaders, setGroupTableHeaders] = useState([]);
    // const [selectedGroup, setSelectedGroup] = useState(0);

    // const handleSelectChange = (id) => {
    //     if (id === selectedGroup) {
    //         setSelectedGroup(0);
    //     }
    //     setSelectedGroup(id);
    // };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    useEffect(() => {
        const subdomain = getSubdomain();
        // setGroupTableHeaders();
        const url = settings.api(JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId).organizationGroupTypes;
        // console.log(url);
        fetch(url, {
            method: "GET",
            headers: {
                "X-Tenant": subdomain,
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setGroupTypes(data);
            });

        return () => {};
    }, []);

    return (
        <div>
            <Typography variant="h4">Groups Types</Typography>
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 2, mb: 1 }}>
                <div>
                    <Button startIcon={<DownloadIcon />}>Import</Button>
                    <Button startIcon={<FileUploadIcon />}>Export</Button>
                </div>
                <div>
                    <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        component={Link}
                        to={`/admin/organizations/${
                            JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId
                        }/group-types/new`}
                    >
                        New Group Type
                    </Button>
                </div>
            </Stack>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="All Group Types" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <Paper sx={{ mt: 2 }}>
                <CustomTabPanel value={tabValue} index={0}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Search Groups"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Group Type</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="center">Total Groups</TableCell>
                                    <TableCell align="center">Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupTypes.length > 0 ? (
                                    groupTypes.map((g, i) => {
                                        // console.log(g);
                                        return (
                                            <TableRow
                                                key={i}
                                                // hover
                                                // onClick={() =>
                                                //     navigate(
                                                //         `/admin/organizations/${
                                                //             JSON.parse(localStorage.getItem("organization"))?.id
                                                //         }/group-types/${g.id}`,
                                                //         {
                                                //             state: { groupType: g.id },
                                                //         }
                                                //     )
                                                // }
                                            >
                                                <TableCell>
                                                    <Typography>{g?.name}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {g?.description?.substring(0, 25)}{" "}
                                                        {g?.description?.length >= 25 && "..."}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography>{g?.totalGroups}</Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        aria-label="edit"
                                                        component={Link}
                                                        // to={`/admin/organizations/${organizationId}/group-types/${g.id}`}
                                                        to={`/admin/organizations/${organizationId}/group-types/${g.id}`}
                                                        state={{ groupType: g.id }}
                                                        color="primary"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            There is no group type.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomTabPanel>
                {/* <CustomTabPanel value={tabValue} index={1}></CustomTabPanel> */}
            </Paper>
        </div>
    );
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default GroupTypes;
